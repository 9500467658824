import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import PageTitle from '../../components/page-title/PageTitle';
import { Navigate } from 'react-router-dom';
import DateService from '../../services/DateService';

class Details extends BaseComponent {
	displayRecruiter(recruiters: any) {
		var displayList = '';
		recruiters.map((recruiter: any) => {
			displayList += recruiter + ', ';
		});
		return displayList.substring(0, displayList.length - 2);
	}

	render() {
		const queryParameters = new URLSearchParams(window.location.search);
		const appointementId = Number(queryParameters.get('id'));

		var future = JSON.parse(localStorage.getItem('future_appointments') || '[]');
		var past = JSON.parse(localStorage.getItem('past_appointments') || '[]');
		const found_future = future.find((obj: any) => {
			return obj.id === appointementId;
		});

		const found_past = past.find((obj: any) => {
			return obj.id === appointementId;
		});

		const found = found_future ? found_future : found_past ? found_past : undefined;

		return (
			<>
				<div className='fr-container'>
					<div className='fr-grid-row'>
						<div className='fr-col-12'>
							<PageTitle
								imgLink=''
								size='h1'
								title={found?.displayName || 'Aucun rendez vous'}
								description='Trouvez ci-dessous les informations concernant votre rendez-vous.'
							/>
						</div>
					</div>
					<div className='fr-grid-row'>
						<div className='fr-col-12'>
							<div className='fr-follow'>
								{found && (
									<div className='form-container'>
										<div className='fr-grid-row'>
											<span
												className='fr-icon-calendar-2-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Date du rendez-vous</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{DateService.ConvertAppointementDate(found.startDate)}
										</div>

										<div className='fr-grid-row'>
											<span
												className='fr-icon-time-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Heure du rendez-vous</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{DateService.ConvertAppointementHour(found.startDate)} -{' '}
											{DateService.ConvertAppointementHour(found.endDate)}
										</div>

										<div className='fr-grid-row'>
											<span
												className='fr-icon-video-chat-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Modalité</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{found.modality}
										</div>

										<div className='fr-grid-row'>
											<span
												className='fr-icon-user-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Conseiller{found.recruiterDisplayName.length > 1 ? 's' : ''}</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{this.displayRecruiter(found.recruiterDisplayName)}
										</div>

										<div className='fr-grid-row'>
											<span
												className='fr-icon-phone-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Numéro de téléphone de contact</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{found.phoneNumber}
										</div>

										<div className='fr-grid-row'>
											<span
												className='fr-icon-home-4-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Adresse du rendez-vous</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{found.cirfaName}
											<br />
											{found.street}
											<br />
											{found.zipCode} {found.cityName}
										</div>

										<div className='fr-grid-row'>
											<span
												className='fr-icon-draft-line'
												style={{ color: '#000091' }}>
												{' '}
												<b>Description</b>
											</span>
										</div>
										<div
											className='fr-grid-row'
											style={{ paddingLeft: '30px', paddingBottom: '25px' }}>
											{found.description}
										</div>
									</div>
								)}
								{found == undefined && (
									<Navigate
										to='/rendez-vous'
										replace={true}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Details;
