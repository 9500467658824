import { useEffect, useState } from 'react';
import { EventCustomer } from '../../../models';
import { DateService } from '../../../services';
import TileSmall from '../../tile-small/TileSmall';
import Accordion from '../../accordion/Accordion';
import Button, { ButtonSize, ButtonType } from '../../button/Button';
import AppointmentAvailabilityService, {
	AppointmentAvailabilitiesDisplayModel,
	AppointmentAvailabilityTimeSlot
} from '../../../services/public/AppointmentAvailabilityPublicService';
import { AppointmentStepProps } from '../../../models/ComponentStepProps';

function CreneauRDVStep({ model, handleChange }: AppointmentStepProps) {
	const [accordions, setAccordions] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [countAppointment, setCountAppointment] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [lastDateMoreSlots, setLastDateMoreSlots] = useState<AppointmentAvailabilityTimeSlot[] | undefined>(undefined);

	useEffect(() => {
		setIsLoading(true);
		AppointmentAvailabilityService.search({
			TenantID: 3,
			CirfaID: model.cirfaId || 0,
			ConfigurationID: model.configurationId!,
			Modality: model.appointmentModalityTypeSelected,
			PageNumber: model.pageNumber,
			PageSize: 100
		})
			.then(async (resp) => {
				const appointmentsAvailabilities = resp?.data;
				appointmentsAvailabilities.forEach((x, index: number) => (x.id = index.toString()));
				if (appointmentsAvailabilities[0]) {
					appointmentsAvailabilities[0].appointmentAvailabilityTimeSlots = appointmentsAvailabilities[0].appointmentAvailabilityTimeSlots.filter(x => lastDateMoreSlots ? lastDateMoreSlots.map(y => y.date).indexOf(x.date) === -1 : true);
				}
				let hasMore = appointmentsAvailabilities[0] ? appointmentsAvailabilities[0].hasMore : false;
				setHasMore(hasMore);
				if (hasMore) {
					let dates = appointmentsAvailabilities[0].appointmentAvailabilityTimeSlots.map(x => x.date);
					let lastDate =  dates.reduce((a, b) => { return a > b ? a : b; }).toString();
					await AppointmentAvailabilityService.search({
						TenantID: 3,
						CirfaID: model.cirfaId || 0,
						ConfigurationID: model.configurationId!,
						Modality: model.appointmentModalityTypeSelected,
						PageNumber: model.pageNumber + 1,
						PageSize: 100
					}).then((resp) => {
						let appointmentAvailabilitiesMore = resp?.data;
						let lastDateMoreSlots = appointmentAvailabilitiesMore.at(0)?.appointmentAvailabilityTimeSlots.filter(x => x.date.toString().slice(0, 10) == lastDate.slice(0, 10));
						setLastDateMoreSlots(lastDateMoreSlots);
						appointmentsAvailabilities[0].appointmentAvailabilityTimeSlots = appointmentsAvailabilities[0].appointmentAvailabilityTimeSlots.concat(lastDateMoreSlots!);
					});
				}
				UpdateAccordion(appointmentsAvailabilities);
				setIsLoading(false);
				setCountAppointment(appointmentsAvailabilities.length);
			})
			.catch((reason: any) => {
				setIsLoading(false);
			});
	}, [model.pageNumber]);

	const UpdateAccordion = (appointmentAvailabilities: AppointmentAvailabilitiesDisplayModel[]) => {
		const accordions = appointmentAvailabilities
			.filter((x: AppointmentAvailabilitiesDisplayModel) => x.configurationId == model.configurationId)
			.flatMap((x: AppointmentAvailabilitiesDisplayModel) => x.appointmentAvailabilityTimeSlots)
			.map((x: any) => DateService.GetDateWithoutHours(x.date))
			.reduce((previous: any, current: any) => {
				if (
					previous.length === 0 ||
					!!!previous.find(
						(x: any) =>
							DateService.GetDateWithoutHours(x).getTime() == DateService.GetDateWithoutHours(current).getTime()
					)
				)
					previous.push(current);
				return previous;
			}, [] as Date[])
			.sort((previous: any, current: any) => {
				return new Date(previous).getTime() - new Date(current).getTime();
			})
			.map((a: any, index: number) => {
				const element = (
					<ul className='fr-grid-row fr-grid-row--gutters'>
						{appointmentAvailabilities
							.filter((x: any) => x.configurationId == model.configurationId)
							.flatMap((x: any) => x.appointmentAvailabilityTimeSlots)
							.filter(
								(x: any) =>
									DateService.GetDateWithoutHours(x.date).getTime() ===
									DateService.GetDateWithoutHours(a).getTime()
							)
							.map((x: any) => {
								return (
									<li
										style={{ listStyleType : 'none' }}
										key={x.appointmentId}
										className='fr-col-6 fr-col-md-4 fr-col-lg-4'>
										<TileSmall
											title={x.startTime.substring(0, x.startTime.length - 3)}
											description={`${x.durationInMinutes} min`}
											onClick={(e) => {
												handleChange({ name: 'appointmentSlotSelected', value: x });
											}}
											selected={x.date === model.appointmentSlotSelected?.date}></TileSmall>
									</li>
								);
							})}
					</ul>
				);
				return (
					<Accordion
						key={index}
						id={`appointment-${a}`}
						title={new Date(a).toLocaleDateString('fr-FR', {
							weekday: 'long',
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})}
						element={element}></Accordion>
				);
			});
		setAccordions(accordions);
	};

	const handleNextTimeslots = (e: any) => {
		const currentPageNumber = model.pageNumber + 1;

		let eventPageNumber: EventCustomer = { name: 'pageNumber', value: currentPageNumber };
		handleChange(eventPageNumber);

		e.preventDefault();
	};

	const handlePreviousTimeslots = (e: any) => {
		let currentPageNumber = model.pageNumber - 1;

		let eventPageNumber: EventCustomer = { name: 'pageNumber', value: currentPageNumber };
		handleChange(eventPageNumber);

		e.preventDefault();
	};

	return (
		<div>
			<h3 className='fr-stepper__title'>Sélectionner la date et l’heure de votre rendez-vous</h3>

			{isLoading && <div className='loader'></div>}
			{!isLoading && <>{accordions}</>}
			<div className='container-button'>
				<Button
					className='button-center fr-btn fr-btn--icon-left fr-icon-arrow-left-s-fill'
					size={ButtonSize.Medium}
					text='Créneaux précédents'
					type={ButtonType.Tertiary}
					onClick={handlePreviousTimeslots}
					disabled={model.pageNumber == 1}
				/>
				<Button
					className='button-center fr-btn fr-btn--icon-right fr-icon-arrow-right-s-fill'
					size={ButtonSize.Medium}
					text='Créneaux suivants'
					type={ButtonType.Tertiary}
					disabled={!hasMore}
					onClick={handleNextTimeslots}
				/>
			</div>
		</div>
	);
}

export default CreneauRDVStep;
