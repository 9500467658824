import React from 'react';
import LinkHeader from './LinkHeader';
import ButtonHeader from './ButtonHeader';
import { v4 as uuid } from 'uuid';

class Header extends React.Component<
	{ title_part_1: string; title_part_2: string; armyLogoLink: string; items: HeaderItem[]; location: string },
	{}
> {
	render() {
		var itemElements = this.props.items.map((x) => {
			return (
				<li key={x.Id}>
					{
					x.Link &&
					<a
						className={'fr-link ' + x.IconClass}
						href={x.Link}
						aria-controls={x.ModalId}
						data-fr-opened='false'>
						{x.DisplayName}
					</a>
					}
					{
						!x.Link &&
						<button
						className={'fr-link ' + x.IconClass}
						aria-controls={x.ModalId}
						data-fr-opened='false'>
						{x.DisplayName}</button>
					}
				</li>
			);
		});
		return (
			<header
				role='banner'
				className='fr-header'>
				<div className='fr-header__body'>
					<div className='fr-container'>
						<div className='fr-header__body-row'>
							<div className='fr-header__brand fr-enlarge-link'>
								<div className='fr-header__brand-top'>
									<div className='fr-header__logo'>
										<p className='fr-logo'>
											{this.props.title_part_1}
											<br />
											{this.props.title_part_2}
										</p>
									</div>
									<div
										className='fr-header__operator'
										style={{ margin: 'auto' }}>
										<img
											src={this.props.armyLogoLink}
											className='fr-responsive-img'
											alt={
												this.props.armyLogoLink.includes('adt')
													? 'Armée de Terre'
													: this.props.armyLogoLink.includes('mn')
													  ? 'Marine Nationale'
													  : this.props.armyLogoLink.includes('aae')
													    ? "Armée de l'Air et de l'Espace"
													    : ''
											}
											style={{ width: '5rem', maxHeight: '70px', objectFit: 'contain' }}
										/>
									</div>
									<div className='fr-header__navbar'>
										<button
											className='fr-btn--menu fr-btn'
											data-fr-opened='false'
											aria-controls='modal-833'
											aria-haspopup='menu'
											title='Menu'
											id='fr-btn-menu-mobile'>
											Menu
										</button>
									</div>
								</div>
							</div>
							<div className='fr-header__tools'>
								<div className='fr-header__tools-links'>
									<ul className='fr-links-group'>{itemElements}</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className='fr-header__menu fr-modal'
					id='modal-833'
					aria-labelledby='fr-btn-menu-mobile'>
					<div className='fr-container'>
						<button
							className='fr-link--close fr-link'
							aria-controls='modal-833'>
							Fermer
						</button>
						<div className='fr-header__menu-links'></div>
						{localStorage.getItem('auth') && (
							<nav
								className='fr-nav'
								id='navigation-773'
								role='navigation'
								aria-label='Menu principal'>
								<ul className='fr-nav__list'>
									<LinkHeader
										link='/'
										label='Accueil'
										isActive={this.props.location == '/'}></LinkHeader>
									<LinkHeader
										link='/rendez-vous'
										label='Mes rendez-vous'
										isActive={
											this.props.location == '/rendez-vous' || this.props.location == '/rendez-vous/detail'
										}></LinkHeader>
									<LinkHeader
										link='/postes-souhaites'
										label='Gestion des postes souhaités'
										isActive={this.props.location == '/postes-souhaites'}></LinkHeader>
									<li className='fr-nav__item'>
										<ButtonHeader
											label='Mon dossier de candidature'
											isActive={
												this.props.location == '/infos-perso' ||
												this.props.location == '/competences' ||
												this.props.location == '/securite'
											}
											ariaControls='menu-774'></ButtonHeader>
										<div
											className='fr-collapse fr-menu'
											id='menu-774'>
											<ul className='fr-menu__list'>
												<LinkHeader
													link='/infos-perso'
													label='Informations personnelles'
													isActive={this.props.location == '/infos-perso'}></LinkHeader>
												<LinkHeader
													link='/competences'
													label='Compétences'
													isActive={this.props.location == '/competences'}></LinkHeader>
												<LinkHeader
													link='/securite'
													label='Sécurité'
													isActive={this.props.location == '/securite'}></LinkHeader>
											</ul>
										</div>
									</li>
								</ul>
							</nav>
						)}
					</div>
				</div>
			</header>
		);
	}
}
export interface HeaderItem {
	Id: number;
	DisplayName: string;
	Link?: string;
	IconClass: string;
	ModalId?: string;
}

export default Header;
