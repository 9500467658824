import { ComponentStepProps, EventCustomer } from '../../../models';
import Input, { InputType } from '../../input/Input';
import { FormatCheckService } from '../../../services';
import { useEffect, useState } from 'react';
import Checkbox from '../../checkbox/Checkbox';
import axios from 'axios';
import { AppointmentStepProps } from '../../../models/ComponentStepProps';

declare var grecaptcha: any;

function ConfirmationRDV({ model, handleChange }: AppointmentStepProps) {
	const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

	useEffect(() => {
		setPhoneNumberErrorMessage(
			model.phoneNumber !== '' && !FormatCheckService.checkPhoneNumber(model.phoneNumber)
				? `Le format de numéro de téléphone saisi n’est pas valide. Format attendu : (+33)xxxxxxxxx`
				: ''
		);
	}, [model.phoneNumber]);

	useEffect(() => {
		if (process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY) {
			grecaptcha.enterprise.ready(() => {
				grecaptcha.enterprise.render('sparta-captcha', {
					sitekey: process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY,
					callback: () => handleCapchaChange(),
					'expired-callback': () => handleCapchaChange()
				});
			});
		}
	}, []);

	const handleCapchaChange = () => {
		var recaptchaResponse = grecaptcha.enterprise.getResponse();
		axios.post(process.env.REACT_APP_RECAPTCHA_EVALUATION_URL!, {
			event: {
				token: recaptchaResponse,
				siteKey: process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY
			}
		});
		const isValidCapcha =
			(process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY && recaptchaResponse) ||
			!process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY;

		const evtIsValidCapcha: EventCustomer = { name: 'isValidCapcha', value: isValidCapcha };
		handleChange(evtIsValidCapcha);
	};

	return (
		<div>
			<h3 className='fr-stepper__title'>Vérifiez vos informations personnelles pour confirmer votre rendez-vous</h3>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Input
				title='Nom'
				type={InputType.Text}
				disabled={true}
				value={model.lastname}
			/>
			<Input
				title='Prénom'
				type={InputType.Text}
				disabled={true}
				value={model.firstname}
			/>
			<Input
				title='Adresse électronique'
				type={InputType.Email}
				disabled={true}
				value={model.email}
			/>
			<Input
				title='Numéro de téléphone'
				description='Format attendu : (+33)xxxxxxxxx'
				name='phoneNumber'
				type={InputType.Text}
				value={model.phoneNumber || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={phoneNumberErrorMessage}
			/>
			<hr className='fr-hr' />
			<h3 className='fr-stepper__title'>Ajouter des informations complémentaires</h3>
			<p className='fr-hint-text account-creation-hint'>
				Vous avez la possibilité de renseignez, toutes informations utiles à votre candidature, comme par exemple : votre
				diplôme le plus élevé obtenu...
			</p>
			<Input
				title='Informations complémentaires (facultatif)'
				name='complementaryInformation'
				type={InputType.TextArea}
				value={model.complementaryInformation}
				onChangeEvent={(e: any) => handleChange(e)}
			/>
			{process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY && (
				<>
					<hr className='fr-hr' />
					<h5>Code de sécurité</h5>
					{<div id='sparta-captcha'></div>}
				</>
			)}
			<Checkbox
				name='acceptance'
				items={[
					{
						Checked: model.acceptance ?? false,
						DisplayName:
							'En cochant cette case vous acceptez que vos données personnelles soient collectées par le Major général de ' +
							'l’Armée de terre (MGAT) et traitées aux fins de prise de rendez-vous en ligne. A défaut, votre demande de rendez-vous ne pourrait aboutir. ' +
							'Veuillez consulter notre politique de confidentialité en <a href="" target="_blank">cliquant ici</a>',
						Value: model.acceptance ?? false
					}
				]}
				onChange={(e: any) => handleChange(e)}></Checkbox>
		</div>
	);
}

export default ConfirmationRDV;
