import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import Radio, { RadioItem } from '../../components/radio/Radio';
import EnumGroupingType from '../../enums/EnumGroupingType';
import ReferentialsPublicService from '../../services/public/ReferentialsPublicService';

interface IRenseignementsState extends IProfileState {
	isGendersLoading: boolean;
}

class Renseignements extends BaseComponent<IRenseignementsState> {
	state: IRenseignementsState = {
		isGendersLoading: true,
		isEdit: false
	};
	genderItems: RadioItem[] = [];

	groupingType: number = EnumGroupingType.SECURITE_RENSEIGNEMENTS;

	constructor(props: any) {
		super(props);
		this.registerGetHook(async () => {
			await ReferentialsPublicService.getGenders().then((genders) => {
				this.genderItems = genders.map((x) => {
					return {
						DisplayName: x.name,
						Value: x.id,
						Checked: x.id === this.state.profile?.personal?.identity?.genderId
					};
				});
				this.setState({ isGendersLoading: false });
			});
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title="Renseignement sur l'intéressé"
							description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations.'
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									{!this.state.isGendersLoading && (
										<Radio
											disabled={true}
											isHorizontal={false}
											items={this.genderItems}
											isSmall={false}
											name='gender'
											title='Sexe'
											onChange={(e) => {
												if (e.currentTarget) {
													this.genderItems
														.filter((x) => x.Value !== +e.currentTarget.value)
														.map((x) => (x.Checked = false));
													this.genderItems
														.filter((x) => x.Value === +e.currentTarget.value)
														.map((x) => (x.Checked = true));
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																identity: {
																	...this.state.profile?.personal?.identity,
																	genderId: +e.currentTarget?.value
																}
															}
														}
													});
												}
											}}></Radio>
									)}
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Nom'
										description=''
										value={this.state.profile?.personal?.identity?.lastName || ''}
										type={InputType.Text}
										disabled={true}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Prénom'
										value={this.state.profile?.personal?.identity?.firstName || ''}
										type={InputType.Text}
										disabled={true}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Identifiant défense (NID)'
										value={this.state.profile?.personal?.identity?.nid}
										type={InputType.Text}
										disabled={true}></Input>
								</div>
							</div>
						</FormContainer>
					</div>
				</div>
			</Container>
		);
	}
}
export default Renseignements;
