import Radio, { RadioItem } from '../../components/radio/Radio';
import Input, { InputType } from '../../components/input/Input';
import { SelectItem } from '../../components/select/Select';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import PageTitle from '../../components/page-title/PageTitle';
import Tabs from '../../components/tabs/Tabs';
import TabItem from '../../components/tabs/TabItem';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Container from '../../components/container/Container';
import { BrowserView, MobileView } from 'react-device-detect';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import ReferentialsPublicService from '../../services/public/ReferentialsPublicService';
import DateService from '../../services/DateService';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../services/AttachmentService';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import RequiredField from '../../models/RequiredField';
import FieldCheckerService from '../../services/FieldCheckerService';
import GroupingValidationService from '../../services/GroupingValidationService';
import { NephosESLScopPoolDocument } from '../../sparta.api';
import FormatCheckService from '../../services/FormatCheckService';
import NationSelect from '../../components/referential-select/NationSelect';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import Modal from '../../components/modal/Modal';
import ReferentialSelectOptimise from '../../components/referential-select/ReferentialSelectOptimise';

interface IMaritalStatusState extends IProfileState {
	isNationsLoading: boolean;
	isGendersLoading: boolean;
	isCitiesLoading: boolean;
	zipCode?: string;
	lastnameError: string;
	firstnameError: string;
	dateOfBirthError: string;
	isValid: boolean;
	isUploadIDCard1: boolean;
	isDeleteIDCard1: boolean;
	isUploadIDCard2: boolean;
	isDeleteIDCard2: boolean;
	isUploadCensus: boolean;
	isDeleteCensus: boolean;
	isUploadBirthAct: boolean;
	isDeleteBirthAct: boolean;
	isUploadCV: boolean;
	isDeleteCV: boolean;
	isUploadMotivationLetter: boolean;
	isDeleteMotivationLetter: boolean;
	isUploadIDPicture: boolean;
	isDeleteIDPicture: boolean;
	showCensusCertificate: boolean;
	isUploadNationalWard: boolean;
	isDeleteNationalWard: boolean;
}

class MaritalStatus extends BaseComponent<IMaritalStatusState> {
	genderItems: RadioItem[] = [];
	cityItems: SelectItem[] = [];
	army: any;

	groupingType: number = EnumGroupingType.INFORMATIONS_PERSONNELLES_ETAT_CIVIL;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_SEXE,
			this.state.profile?.personal?.identity?.genderId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_NOM,
			this.state.profile?.personal?.identity?.lastName,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_PRENOM,
			this.state.profile?.personal?.identity?.firstName,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_DATE_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.dateOfBirth,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_NATIONALITE_ACTUELLE,
			this.state.profile?.personal?.identity?.nationalities?.currentNationalityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_PAYS_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.birthCountryId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_COMMUNE_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.birthCityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_COMMUNE_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.birthCity?.name,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_CODE_POSTAL_DE_LA_COMMUNE_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.birthCityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_CODE_POSTAL_DE_LA_COMMUNE_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.birthCity?.zipCode,
			this.requiredFields
		);

		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_CARTE_D_IDENTITE_NATIONALE_OU_PASSEPORT,
			this.state.profile?.personal?.identity?.nationalIdCardOrPassPort?.at(0)?.id,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_EXTRAIT_D_ACTE_DE_NAISSANCE,
			this.state.profile?.personal?.identity?.birthAct?.id,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_ATTESTATION_DE_RECENSEMENT,
			this.state.profile?.personal?.identity?.censusCertificate?.id,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_CV_DU_CANDIDAT,
			this.state.profile?.curriculumVitae?.id,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_LETTRE_DE_MOTIVATION,
			this.state.profile?.motivationLetter?.id,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ETAT_CIVIL_PHOTO_D_IDENTITE,
			this.state.profile?.personal?.identity?.picture?.id,
			this.requiredFields
		);
	}

	isValidDateOfBirth() {
		let age = DateService.getAgeFromString(this.state.profile?.personal?.identity?.dateOfBirth!);

		let result =
			this.state.profile?.personal?.identity?.dateOfBirth != null &&
			this.state.profile?.personal?.identity?.dateOfBirth != undefined &&
			age > this.army?.minAge - 1 &&
			age < +(localStorage.getItem('maxAge') || this.army?.maxAge) + 1;

		return result;
	}

	isValid() {
		this.setState({ isValid: !this.state.firstnameError && !this.state.lastnameError && this.isValidDateOfBirth() });
	}

	setIDCardAtIndex(index: number, guid?: string) {
		var nationalIdCardOrPassport = this.state.profile?.personal?.identity?.nationalIdCardOrPassPort
			? [...this.state.profile?.personal?.identity?.nationalIdCardOrPassPort]
			: [];
		nationalIdCardOrPassport[index] = {
			id: guid,
			validationState: AttachmentState.WAITING_FOR_VALIDATION
		};
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						nationalIdCardOrPassPort: nationalIdCardOrPassport
					}
				}
			},
			isUploadIDCard1: this.state.isUploadIDCard1 || index === 0,
			isUploadIDCard2: this.state.isUploadIDCard2 || index === 1
		});
	}

	deleteIDCardAtIndex(index: number) {
		var nationalIdCardOrPassport = this.state.profile?.personal?.identity?.nationalIdCardOrPassPort
			? [...this.state.profile?.personal?.identity?.nationalIdCardOrPassPort]
			: [];
		if (nationalIdCardOrPassport.length) {
			nationalIdCardOrPassport[index] = {};
		}
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						nationalIdCardOrPassPort: nationalIdCardOrPassport
					}
				}
			},
			isDeleteIDCard1: this.state.isDeleteIDCard1 || index === 0,
			isDeleteIDCard2: this.state.isDeleteIDCard2 || index === 1
		});
	}

	setCensus(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						censusCertificate: {
							id: guid,
							validationState: AttachmentState.WAITING_FOR_VALIDATION
						}
					}
				}
			},
			isUploadCensus: true
		});
	}

	deleteCensus() {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						censusCertificate: undefined
					}
				}
			},
			isDeleteCensus: true
		});
	}

	setBirthAct(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						birthAct: {
							id: guid,
							validationState: AttachmentState.WAITING_FOR_VALIDATION
						}
					}
				}
			},
			isUploadBirthAct: true
		});
	}

	deleteBirthAct() {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						birthAct: undefined
					}
				}
			},
			isDeleteBirthAct: true
		});
	}

	setCV(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				curriculumVitae: {
					id: guid,
					validationState: AttachmentState.WAITING_FOR_VALIDATION
				}
			},
			isUploadCV: true
		});
	}

	deleteCV() {
		this.setState({
			profile: {
				...this.state.profile,
				curriculumVitae: undefined
			},
			isDeleteCV: true
		});
	}

	setMotivationLetter(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				motivationLetter: {
					id: guid,
					validationState: AttachmentState.WAITING_FOR_VALIDATION
				}
			},
			isUploadMotivationLetter: true
		});
	}

	deleteMotivationLetter() {
		this.setState({
			profile: {
				...this.state.profile,
				motivationLetter: undefined
			},
			isDeleteMotivationLetter: true
		});
	}

	setIDPicture(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						picture: {
							id: guid,
							validationState: AttachmentState.WAITING_FOR_VALIDATION
						}
					}
				}
			},
			isUploadIDPicture: true
		});
	}

	deleteIDPicture(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					identity: {
						...this.state.profile?.personal?.identity,
						picture: undefined
					}
				}
			},
			isDeleteIDPicture: true
		});
	}

	setNationalWard(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					nationalWardAttachment: {
						id: guid,
						validationState: AttachmentState.WAITING_FOR_VALIDATION
					}
				}
			},
			isUploadNationalWard: true
		});
	}

	deleteNationalWard() {
		this.setState({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					nationalWardAttachment: undefined
				}
			},
			isDeleteNationalWard: true
		});
	}

	constructor(props: any) {
		super(props);

		this.state = {
			profile: {},
			showFreeCityFieldsForBirthCountryId: false,
			groupingIsValidate: false,
			isCitiesLoading: true,
			isGendersLoading: true,
			isNationsLoading: true,
			dateOfBirthError: '',
			lastnameError: '',
			firstnameError: '',
			isValid: true,
			isEdit: false,
			isUploadIDCard1: false,
			isDeleteIDCard1: false,
			isUploadIDCard2: false,
			isDeleteIDCard2: false,
			isUploadCensus: false,
			isDeleteCensus: false,
			isUploadBirthAct: false,
			isDeleteBirthAct: false,
			isUploadCV: false,
			isDeleteCV: false,
			isUploadMotivationLetter: false,
			isDeleteMotivationLetter: false,
			isUploadIDPicture: false,
			isDeleteIDPicture: false,
			showCensusCertificate: true,
			isUploadNationalWard: false,
			isDeleteNationalWard: false
		};

		this.registerGetHook(async () => {
			await ReferentialsPublicService.getGenders().then((genders) => {
				this.genderItems = genders.map((x) => {
					return {
						DisplayName: x.name,
						Value: x.id,
						Checked: x.id === this.state.profile?.personal?.identity?.genderId
					};
				});
				this.setState({ isGendersLoading: false });
			});
		});

		ReferentialsService.getReferential(ReferentialType.ARMY_PUBLIC, +(ReferentialsService.getArmy() || '')).then((x) => {
			var resp = { ...x.data };
			resp.name = (resp.name as string).toLowerCase();
			if (resp.name[0] === 'a') {
				resp.name = "l'" + resp.name;
			} else {
				resp.name = 'la ' + resp.name;
			}

			this.army = resp;

			if (this.army?.id == 3) {
				this.setState({ showCensusCertificate: true });
			} else {
				this.setState({ showCensusCertificate: false });
			}
		});
	}

	initUpload() {
		this.setState({
			isUploadIDCard1: false,
			isDeleteIDCard1: false,
			isUploadIDCard2: false,
			isDeleteIDCard2: false,
			isUploadCensus: false,
			isDeleteCensus: false,
			isUploadBirthAct: false,
			isDeleteBirthAct: false,
			isUploadCV: false,
			isDeleteCV: false,
			isUploadMotivationLetter: false,
			isDeleteMotivationLetter: false,
			isUploadIDPicture: false,
			isDeleteIDPicture: false,
			isUploadNationalWard: false,
			isDeleteNationalWard: false
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='État civil / Identité'
							description='Veuillez vérifier et/ou remplir les champs d’informations ci-dessous, mais également importer les différentes pièces jointes demandées'
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							className='tabs-with-overflow'
							mobileOnly={true}
							isFormContainer={true}
							items={[
								{
									displayName: 'Formulaire',
									id: 'form',
									tabIndex: 0,
									selected: true
								},
								{
									displayName: 'Pièces jointes',
									id: 'attachments',
									tabIndex: 1,
									selected: false
								}
							]}>
							<TabItem
								id='form'
								tabIndex={0}
								selected={true}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12 fr-col-md-6'>
										{!this.state.isGendersLoading && (
											<Radio
												name='sexe'
												title='Sexe'
												isHorizontal={false}
												items={this.genderItems}
												disabled={this.state.groupingIsValidate ?? false}
												isSmall={false}
												errorMessage=''
												onChange={(e) => {
													if (e.currentTarget) {
														this.genderItems
															.filter((x) => x.Value !== +e.currentTarget.value)
															.map((x) => (x.Checked = false));
														this.genderItems
															.filter((x) => x.Value === +e.currentTarget.value)
															.map((x) => (x.Checked = true));
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	identity: {
																		...this.state.profile?.personal?.identity,
																		genderId: +e.currentTarget?.value
																	}
																}
															}
														});
													}
												}}></Radio>
										)}
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Nom'
											description=''
											value={this.state.profile?.personal?.identity?.lastName || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Text}
											iconClass=''
											onChange={(e) => {
												var isValid = FormatCheckService.checkLastName(e);
												this.setState(
													{
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																identity: {
																	...this.state.profile?.personal?.identity,
																	lastName: e
																}
															}
														},
														lastnameError: isValid == false ? 'Le format du nom est erroné' : ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.lastnameError}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Prénom'
											description=''
											value={this.state.profile?.personal?.identity?.firstName || ''}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Text}
											iconClass=''
											onChange={(e) => {
												var isValid = FormatCheckService.checkFirstName(e);
												this.setState(
													{
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																identity: {
																	...this.state.profile?.personal?.identity,
																	firstName: e
																}
															}
														},
														firstnameError: isValid == false ? 'Le format du prénom est erroné' : ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.firstnameError}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de naissance'
											description=''
											value={
												DateService.Convert(this.state.profile?.personal?.identity?.dateOfBirth || '') ||
												''
											}
											disabled={this.state.groupingIsValidate ?? false}
											type={InputType.Date}
											onChange={(e) => {
												this.setState(
													{
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																identity: {
																	...this.state.profile?.personal?.identity,
																	dateOfBirth: e
																}
															}
														}
													},
													() => {
														if (
															this.state.profile?.personal?.identity?.dateOfBirth &&
															DateService.getAgeFromString(
																this.state.profile?.personal?.identity?.dateOfBirth
															) < this.army?.minAge
														) {
															this.setState({
																dateOfBirthError: `Vous devez avoir plus de ${this.army?.minAge} ans pour vous engager dans ${this.army?.name}.`
															});
														} else if (
															this.state.profile?.personal?.identity?.dateOfBirth &&
															DateService.getAgeFromString(
																this.state.profile?.personal?.identity?.dateOfBirth
															) > +(localStorage.getItem('maxAge') || this.army?.maxAge)
														) {
															this.setState({
																dateOfBirthError:
																	"Votre âge ne vous permet plus de vous engager comme soldat, mais vous pouvez encore devenir réserviste en appelant votre CIRFA ou entrer dans <a href='https://www.legion-recrute.com/fr' title='La légion étrangère' target='_blank' rel='noopener'>la légion étrangère</a>"
															});
														} else {
															this.setState({
																dateOfBirthError: ''
															});
														}

														this.isValid();
													}
												);
											}}
											errorMessage={this.state.dateOfBirthError}></Input>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Nationalité Actuelle'
											onChange={(e) => {
												this.setState(() => ({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															identity: {
																...this.state.profile?.personal?.identity,
																nationalities: {
																	currentNationalityId: e?.id
																}
															}
														}
													}
												}));
											}}
											value={this.state.profile?.personal?.identity?.nationalities?.currentNationalityId}
											disabled={this.state.groupingIsValidate ?? false}
											type={ReferentialType.NATIONALITY}
											customOptionLabel={(o: any) => {
												return o.formattedNationality;
											}}
										/>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<NationSelect
											label='Pays de naissance'
											onChange={(e) => {
												this.setState(() => ({
													showFreeCityFieldsForBirthCountryId: e?.name !== 'FRANCE',
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															identity: {
																...this.state.profile?.personal?.identity,
																birthCountryId: e?.id,
																birthCityId: undefined,
																birthCity: {
																	...this.state.profile?.personal?.identity?.birthCity,
																	id: undefined,
																	zipCode: e?.name === 'MONACO' ? '98000' : '99999',
																	name: '',
																	inseeCode: ''
																}
															}
														}
													}
												}));
											}}
											value={this.state.profile?.personal?.identity?.birthCountryId}
											disabled={this.state.groupingIsValidate ?? false}
											type={ReferentialType.NATION}
											customOptionLabel={(o: any) => o.shortName}
										/>
									</div>
								</div>
								{this.state.showFreeCityFieldsForBirthCountryId && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Input
													title='Code postal de la commune de naissance'
													value={this.state.profile?.personal?.identity?.birthCity?.zipCode}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	identity: {
																		...this.state.profile?.personal?.identity,
																		birthCity: {
																			...this.state.profile?.personal?.identity?.birthCity,
																			zipCode: e
																		}
																	}
																}
															}
														});
													}}
													disabled={this.state.groupingIsValidate ?? false}
													type={InputType.Text}></Input>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Input
													title='Commune de naissance'
													value={this.state.profile?.personal?.identity?.birthCity?.name}
													onChange={(e) => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	identity: {
																		...this.state.profile?.personal?.identity,
																		birthCity: {
																			...this.state.profile?.personal?.identity?.birthCity,
																			name: e,
																			inseeCode: ''
																		}
																	}
																}
															}
														});
													}}
													disabled={this.state.groupingIsValidate ?? false}
													type={InputType.Text}></Input>
											</div>
										</div>
									</>
								)}
								{!this.state.showFreeCityFieldsForBirthCountryId && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Code postal de la commune de naissance'
													onChange={(value) => {
														this.setState({
															zipCode: value?.zipCode,
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	identity: {
																		...this.state.profile?.personal?.identity,
																		birthCityId: undefined,
																		birthCity: {
																			...this.state.profile?.personal?.identity?.birthCity,
																			id: value?.id,
																			zipCode: value?.zipCode
																		}
																	}
																}
															}
														});
													}}
													value={this.state.profile?.personal?.identity?.birthCity?.id}
													disabled={this.state.groupingIsValidate ?? false}
													type={ReferentialType.ZIPCODE}
													customOptionLabel={(o) => o.zipCode}
													zipCode={this.state.profile?.personal?.identity?.birthCity?.zipCode}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<ReferentialSelectOptimise
													label='Commune de naissance'
													onChange={(value) => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	identity: {
																		...this.state.profile?.personal?.identity,
																		birthCityId: value?.id,
																		birthCity: {
																			...this.state.profile?.personal?.identity?.birthCity,
																			id: value?.id,
																			zipCode: value?.zipCode,
																			name: value?.name,
																			inseeCode: value?.inseeCode
																		}
																	}
																}
															}
														});
													}}
													zipCode={this.state.profile?.personal?.identity?.birthCity?.zipCode}
													name={this.state.profile?.personal?.identity?.birthCity?.name}
													value={this.state.profile?.personal?.identity?.birthCityId}
													disabled={this.state.groupingIsValidate ?? false}
													type={ReferentialType.CITY}
												/>
											</div>
										</div>
									</>
								)}
								{ReferentialsService.getArmy() === 2 && (
									<>
										<hr className='fr-hr' />
										<h5>Pupille de la nation</h5>
										<p>Êtes-vous pupille de la nation ?</p>
										<Radio
											items={[
												{
													DisplayName: 'Oui',
													Value: 1,
													Checked: this.state.profile?.personal?.isNationalWard
												},
												{
													DisplayName: 'Non',
													Value: 0,
													Checked: !this.state.profile?.personal?.isNationalWard
												}
											]}
											disabled={this.state.groupingIsValidate ?? false}
											isHorizontal={false}
											isSmall={false}
											name='is-national-ward'
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														personal: {
															...this.state.profile?.personal,
															isNationalWard: e.currentTarget.value === '1'
														}
													}
												});
											}}></Radio>
									</>
								)}
							</TabItem>
							<BrowserView>
								<hr className='fr-hr desktop-only' />
								<h5 className='desktop-only'>Pièces jointes</h5>
							</BrowserView>
							<TabItem
								id='attachments'
								tabIndex={1}
								selected={false}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
										<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											noExplanation
											hintText='Carte d’identité nationale ou passeport (recto)'
											guid={this.state.profile?.personal?.identity?.nationalIdCardOrPassPort?.at(0)?.id}
											onDelete={this.deleteIDCardAtIndex.bind(this, 0)}
											onUpload={this.setIDCardAtIndex.bind(this, 0)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadIDCard1}
											isDeleteInProgress={this.state.isDeleteIDCard1}></AttachmentUpload>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											noExplanation
											hintText='Carte d’identité nationale ou passeport (verso)'
											guid={this.state.profile?.personal?.identity?.nationalIdCardOrPassPort?.at(1)?.id}
											onDelete={this.deleteIDCardAtIndex.bind(this, 1)}
											onUpload={this.setIDCardAtIndex.bind(this, 1)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadIDCard2}
											isDeleteInProgress={this.state.isDeleteIDCard2}></AttachmentUpload>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											noExplanation
											hintText='Extrait d’acte de naissance'
											guid={this.state.profile?.personal?.identity?.birthAct?.id}
											onDelete={this.deleteBirthAct.bind(this)}
											onUpload={this.setBirthAct.bind(this)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadBirthAct}
											isDeleteInProgress={this.state.isDeleteBirthAct}></AttachmentUpload>
									</div>
								</div>
								{this.state.showCensusCertificate && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<AttachmentUpload
													noExplanation
													hintText='Attestation de recensement'
													guid={this.state.profile?.personal?.identity?.censusCertificate?.id}
													onDelete={this.deleteCensus.bind(this)}
													onUpload={this.setCensus.bind(this)}
													isDisabled={this.state.groupingIsValidate ?? false}
													isUploadInProgress={this.state.isUploadCensus}
													isDeleteInProgress={this.state.isDeleteCensus}></AttachmentUpload>
											</div>
										</div>
									</>
								)}
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											noExplanation
											hintText='CV du candidat'
											guid={this.state.profile?.curriculumVitae?.id}
											onDelete={this.deleteCV.bind(this)}
											onUpload={this.setCV.bind(this)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadCV}
											isDeleteInProgress={this.state.isDeleteCV}></AttachmentUpload>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											noExplanation
											hintText='Lettre de motivation'
											guid={this.state.profile?.motivationLetter?.id}
											onDelete={this.deleteMotivationLetter.bind(this)}
											onUpload={this.setMotivationLetter.bind(this)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadMotivationLetter}
											isDeleteInProgress={this.state.isDeleteMotivationLetter}></AttachmentUpload>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<AttachmentUpload
											noExplanation
											hintText='Photo d’identité'
											guid={this.state.profile?.personal?.identity?.picture?.id}
											onUpload={this.setIDPicture.bind(this)}
											onDelete={this.deleteIDPicture.bind(this)}
											isDisabled={this.state.groupingIsValidate ?? false}
											isUploadInProgress={this.state.isUploadIDPicture}
											isDeleteInProgress={this.state.isDeleteIDPicture}></AttachmentUpload>
									</div>
								</div>
								{ReferentialsService.getArmy() === 2 && this.state.profile?.personal?.isNationalWard && (
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<AttachmentUpload
												noExplanation
												hintText='Attestation Pupille de la nation'
												guid={this.state.profile?.personal?.nationalWardAttachment?.id}
												onDelete={this.deleteNationalWard.bind(this)}
												onUpload={this.setNationalWard.bind(this)}
												isDisabled={this.state.groupingIsValidate ?? false}
												isUploadInProgress={this.state.isUploadNationalWard}
												isDeleteInProgress={this.state.isDeleteNationalWard}></AttachmentUpload>
										</div>
									</div>
								)}
							</TabItem>
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												disabled={!this.state.isValid}
												onClick={() => {
													this.updateProfile(() => {
														this.displaySuccess();
														this.initUpload();
													});
												}}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												iconClassName=''
												size={ButtonSize.Medium}
												modalId=''></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={!this.state.isValid}
											onClick={() => {
												this.updateProfile(() => {
													this.displaySuccess();
													this.initUpload();
												});
											}}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}

export default MaritalStatus;
