import React from 'react';
import './0-TileSmall-mobile.scss';

class TileSmall extends React.Component<
	{
		title: string;
		description: string;
		imgLink?: string;
		selected?: boolean;
		onClick?: (e: any) => void;
	},
	{}
> {
	render() {
		return (
			<div
				style={{ backgroundColor: this.props.selected ? 'lightblue' : '' }}
				className='fr-tile fr-enlarge-link'>
				<div className='fr-tile__body'>
					<h4 className='fr-tile__title btn'>
						<button
							className='fr-tile__link'
							onClick={(e) => {
								this.props.onClick && this.props.onClick(e);
								e.preventDefault();
							}}>
							{this.props.title}
						</button>
					</h4>
					<p className='fr-tile__desc'>{this.props.description}</p>
				</div>
				{this.props.imgLink && (
					<div className='fr-tile__img'>
						<img
							src='../img/placeholder.1x1.png'
							className='fr-responsive-img'
							alt=''
						/>
					</div>
				)}
			</div>
		);
	}
}
export default TileSmall;
