import { useEffect, useState } from 'react';
import EmailVerificationPublicService, {
	EmailVerificationResponseStatus
} from '../../../services/public/EmailVerificationPublicService';
import Alert, { AlertSize, AlertType } from '../../alert/Alert';
import CodeInput from '../../codeinput/CodeInput';
import { EventCustomer } from '../../../models';
import { AccountStepProps } from '../../../models/ComponentStepProps';

function ConfirmationEmailStep({ model, handleChange }: AccountStepProps) {
	const [verificationEmailSent, setVerificationEmailSent] = useState(false);

	const sendVerificationEmail = () => {
		if (model.email) {
			let eventverificationCode: EventCustomer = { name: 'verificationCode', value: '' };
			EmailVerificationPublicService.verifyEmail(model.email).then((resp: EmailVerificationResponseStatus) => {
				if (resp === EmailVerificationResponseStatus.SUCCESS) {
					setVerificationEmailSent(true);
				} else {
					setVerificationEmailSent(false);
				}
			});
			handleChange(eventverificationCode);
		}
	};

	useEffect(() => {
		sendVerificationEmail();
	}, []);

	const validateConfirmationCode = (code: string) => {
		if (model.email) {
			if (code && code.length === 6) {
				EmailVerificationPublicService.verifyCode(code, model.email).then((resp: EmailVerificationResponseStatus) => {
					let eventIsConfirmationCodeValid: EventCustomer = { name: 'isConfirmationCodeValid', value: false };
					let eventverificationCode: EventCustomer = { name: 'verificationCode', value: code };
					if (resp === EmailVerificationResponseStatus.SUCCESS) {
						eventIsConfirmationCodeValid.value = true;
					} else {
						eventIsConfirmationCodeValid.value = false;
						eventverificationCode.value = 'error';
					}

					handleChange(eventIsConfirmationCodeValid);
					handleChange(eventverificationCode);
				});
			}
		}
	};

	return (
		<div>
			<h5>Valider votre compte en renseignant le code reçu par courriel ci-dessous</h5>
			<Alert
				size={AlertSize.Small}
				description='Un courriel vous a été envoyé à l’adresse indiquée. Pour confirmer votre compte, renseignez le code reçu dans ce courriel.'
				type={AlertType.Success}
				isShown={verificationEmailSent}
			/>
			<fieldset
				role='group'
				style={{ border: 'none', padding: '0', marginTop: '5px', minInlineSize: 'initial' }}>
				<legend>
					<p className='fr-hint-text email-confirmation-detail'>
						Détail — Si vous n'avez pas reçu de courriel (n'hésitez pas à vérifier dans les indésirables), vous pouvez
						saisir à nouveau votre adresse électronique.
					</p>
				</legend>
				<CodeInput
					allowCharacter={false}
					length={6}
					isError={model.verificationCode === 'error' && model.isConfirmationCodeValid === false}
					errorMessage='Code de confirmation invalide'
					onFullFilled={(e: string) => {
						validateConfirmationCode(e);
					}}
				/>
			</fieldset>
			<br></br>
			<br></br>
			<p style={{ color: 'black', textAlign: 'center' }}>
				Vous n'avez pas reçu de mail ? &nbsp;
				<button
					style={ { textDecoration: 'underline' }}
					className='fr-link'
					onClick={() => sendVerificationEmail()}>
					Me renvoyer le mail
				</button>
			</p>
		</div>
	);
}

export default ConfirmationEmailStep;
