import React from 'react';
import './0-SearchBar-mobile.scss';

class SearchBar extends React.Component<
	{
		onClick: (e: any) => void;
		onChange: (search: string) => void;
		onkeydown: (key: string) => void;
		isLarge: boolean;
		label?: string;
		placeholder: string;
		value?: string;
	},
	{}
> {
	render() {
		var isLargeStr = this.props.isLarge ? 'fr-search-bar--lg' : '';
		return (
			<div
				className={'fr-search-bar ' + isLargeStr}
				id='header-search'>
				<label
					className='fr-label'
					htmlFor='search-784-input'>
					{this.props.label}
				</label>
				<input
					autoComplete='postal-code'
					value={this.props.value}
					className='fr-input'
					placeholder={this.props.placeholder}
					type='search'
					id='search-784-input'
					name='search-784-input'
					onChange={(e) => this.props.onChange(e.target.value)}
					onKeyDown={(e) => this.props.onkeydown(e.key)}></input>
				<button
					className='fr-btn search'
					title='Rechercher'
					onClick={(e) => this.props.onClick(e)}>
					Rechercher
				</button>
			</div>
		);
	}
}

export default SearchBar;
