import { BrowserView, MobileView } from 'react-device-detect';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import DateService from '../../services/DateService';
import FieldCheckerService from '../../services/FieldCheckerService';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import { v4 as uuid } from 'uuid';
import Modal, { ModalSize } from '../../components/modal/Modal';

interface IDomicileActuelState extends IProfileState {
	isDomicileActuelLoading?: boolean;
}

class DomicileActuel extends BaseComponent<IDomicileActuelState> {
	state: IDomicileActuelState = { isEdit: false };
	zipCode: string = '';
	city: string = '';
	country: string = '';
	securityInformationModalId: string = uuid();

	groupingType: number = EnumGroupingType.SECURITE_DOMICILE_ACTUEL;

	constructor(props: any) {
		super(props);
		this.registerGetHook(async () => {
			this.setState({ isDomicileActuelLoading: true }, async () => {
				await ReferentialsService.getReferential(
					ReferentialType.CITY,
					this.state.profile?.personal?.contactDetails?.address?.cityId ?? 0
				).then((x) => {
					this.zipCode = this.state.profile?.personal?.contactDetails?.address?.city?.zipCode ?? '';
					this.city = this.state.profile?.personal?.contactDetails?.address?.city?.name ?? '';
				});
				await ReferentialsService.getReferential(
					ReferentialType.NATION,
					this.state.profile?.personal?.contactDetails?.address?.countryId ?? 0
				).then((x) => {
					this.country = this.state.profile?.personal?.contactDetails?.address?.country?.shortName ?? '';
					this.setState({ isDomicileActuelLoading: false });
				});
			});
		});
	}

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.DOMICILE_ACTUEL_DEPUIS_LE,
			this.state.profile?.personal?.contactDetails?.address?.startDate,
			this.requiredFields
		);
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Domicile actuel'
							description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations et compléter les informations manquantes.'
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							{!this.state.isDomicileActuelLoading && (
								<>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Depuis le'
												value={DateService.Convert(
													this.state.profile?.personal?.contactDetails?.address?.startDate || ''
												)}
												type={InputType.Date}
												disabled={this.state.groupingIsValidate ?? false}
												onChange={(e) => {
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																contactDetails: {
																	...this.state.profile?.personal?.contactDetails,
																	address: {
																		...this.state.profile?.personal?.contactDetails?.address,
																		startDate: DateService.Convert(e)
																	}
																}
															}
														}
													});
												}}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Adresse'
												description='Numéro + rue'
												value={this.state.profile?.personal?.contactDetails?.address?.street}
												type={InputType.Text}
												disabled={true}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title="Complément d'adresse"
												description='Bâtiment, immeuble, escalier...'
												value={
													this.state.profile?.personal?.contactDetails?.address?.additionalInformation
												}
												type={InputType.Text}
												disabled={true}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Code postal'
												value={this.zipCode}
												type={InputType.Text}
												disabled={true}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Ville ou commune'
												value={this.city}
												type={InputType.Text}
												disabled={true}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Pays de résidence'
												value={this.country}
												type={InputType.Text}
												disabled={true}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Numéro de téléphone'
												description='Format attendu : (+33)xxxxxxxxx'
												value={this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(0)?.number}
												type={InputType.Tel}
												disabled={true}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Adresse électronique'
												value={this.state.profile?.personal?.contactDetails?.email}
												type={InputType.Text}
												disabled={true}></Input>
										</div>
									</div>
								</>
							)}
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												className='no-margin'
												disabled={false}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												size={ButtonSize.Medium}
												modalId={this.securityInformationModalId}></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</FormContainer>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											size={ButtonSize.Medium}
											modalId={this.securityInformationModalId}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
				<Modal
					size={ModalSize.Large}
					title='Certifier l’exactitude des informations renseignées'
					id={this.securityInformationModalId}
					buttons={[
						{
							DisplayName: 'Certifier l’exactitude des informations renseignées',
							IconClass: '',
							OnClick: () => {
								this.updateProfile(() => this.displaySuccess());
							}
						},
						{
							DisplayName: 'Annuler',
							IconClass: '',
							Type: ButtonType.Secondary
						}
					]}
					children={
						<>
							<div>
								Vous avez renseigné les informations concernant la section sécurité. Veuillez vérifier que les
								informations sont correctes et que vous reconnaissez les informations suivantes.
							</div>
							<br></br>
							<div>
								Je reconnais être informé(e) :
								<dl style={{ paddingLeft: 0 }}>
									<dt>- du caractère obligatoire des réponses qui me sont demandées ;</dt>
									<dt>
										- de ce que je dispose d'un droit d'accès et de rectification, en application des articles
										34 et suivants de la loi n°78-17 du 6 janvier 1978 relative à l'informatique aux fichiers
										et aux libertés que je pourrai exercer auprès des services du haut fonctionnaire de
										défense et de sécurité du ministère de la défense ;
									</dt>
									<dt>
										- que les informations recueillies font l'objet d'un traitement informatique destiné à la
										gestion de la protection du secret de la défense nationale ;
									</dt>
									<dt>
										- que les destinataires des données de ce traitement sont, en fonction de leurs
										attributions et dans la limite du besoin d'en connaître, les services du haut
										fonctionnaire de défense et de sécurité du ministère de la défense.
									</dt>
								</dl>
							</div>
						</>
					}
				/>
			</Container>
		);
	}
}
export default DomicileActuel;
