import React from 'react';
import { Value } from 'sass';
import ConvertHTML from '../tools/ConvertHTML';
import { v4 as uuidv4 } from 'uuid';

import './0-Input-mobile.scss';

interface IInputState {
	showPassword: boolean;
}

class Input extends React.Component<
	{
		title: string;
		name: string;
		value?: string;
		description?: string;
		type: InputType;
		iconClass?: string;
		isRequired?: boolean;
		isReadonly?: boolean;
		onChange?: (input: string) => void;
		onChangeEvent?: (e: any) => void;
		disabled?: boolean;
		onBlur?: (input: string) => void;
		errorMessage?: string;
		isCustom?: boolean;
		isCurrentPassword?: boolean;
	},
	{}
> {
	state: IInputState = {
		showPassword: false
	};

	inputRef: React.RefObject<HTMLInputElement>;
	id: string = uuidv4();
	static defaultProps: { name: string };

	constructor(props: any) {
		super(props);
		this.inputRef = React.createRef<HTMLInputElement>();
	}

	togglePassword(e: React.MouseEvent) {
		e.stopPropagation();
		this.setState({ showPassword: !this.state.showPassword }, () => {
			if (this.inputRef.current != null) {
				if (this.state.showPassword) this.inputRef.current.type = 'text';
				else this.inputRef.current.type = 'password';
			}
		});
	}

	buildHtml(typeStr: any, errorInputStr: any) {
		return (
			<>
				{this.props.type == InputType.TextArea && (
					<textarea
						className='fr-input'
						value={this.props.value}
						id={this.id}
						name={this.props.name || ''}
						required={this.props.isRequired}
						onChange={(e) => {
							if (this.props.onChange) {
								this.props.onChange(e.currentTarget.value);
							}
							if (this.props.onChangeEvent) {
								this.props.onChangeEvent(e);
							}
						}}
						disabled={this.props.disabled}
						onBlur={(e) => {
							if (this.props.onBlur) {
								this.props.onBlur(e.currentTarget.value);
							}
						}}></textarea>
				)}
				{this.props.type !== InputType.TextArea && this.props.type !== InputType.Date && (
					<input
						ref={this.inputRef}
						value={this.props.value}
						aria-describedby={
							this.props.errorMessage ? 'text-input-error-desc-error-' + this.id.toString() : undefined
						}
						autoComplete={
							this.props.title == 'Nom' || this.props.title == 'Nom de naissance'
								? 'family-name'
								: this.props.title.includes('Prénom')
								  ? 'given-name'
								  : this.props.title.includes('téléphone')
								    ? 'tel-national'
								    : this.props.type === InputType.Email
								      ? 'email'
								      : this.props.title.includes('Adresse électronique')
								        ? 'email'
								        : this.props.title.includes('Code postal')
								          ? 'postal-code'
								          : this.props.title.includes('Pays')
								            ? 'country-name'
								            : this.props.title == 'Adresse'
								              ? 'address-line1'
								              : this.props.title == 'Complément d’adresse'
								                ? 'address-line2'
								                : this.props.title.includes('Ville') || this.props.title.includes('Commune')
								                  ? 'address-line3'
								                  : this.props.type === InputType.Password &&
								                      (this.props.title == 'Mot de passe' ||
																				(this.props.title ==
																					'Confirmation du mot de passe' &&
																					!this.props.isCurrentPassword))
								                    ? 'new-password'
								                    : this.props.type === InputType.Password &&
								                        this.props.title == 'Mot de passe' &&
								                        this.props.isCurrentPassword
								                      ? 'current-password'
								                      : this.props.title == 'Identifiant'
								                        ? 'username'
								                        : undefined
						}
						className={'fr-input' + (this.props.type === InputType.Tel ? ' tel-input' : '') + errorInputStr}
						type={typeStr}
						readOnly={this.props.isReadonly || this.props.disabled}
						id={this.id}
						name={this.props.name || ''}
						required={this.props.isRequired}
						onChange={(e) => {
							if (this.props.onChange) {
								this.props.onChange(e.currentTarget.value);
							}
							if (this.props.onChangeEvent) {
								this.props.onChangeEvent(e);
							}
						}}
						disabled={this.props.disabled}
						onBlur={(e) => {
							if (this.props.onBlur) {
								this.props.onBlur(e.currentTarget.value);
							}
						}}></input>
				)}
				{this.props.type !== InputType.TextArea && this.props.type === InputType.Date && (
					<input
						ref={this.inputRef}
						value={this.props.value}
						aria-describedby={
							this.props.errorMessage ? 'text-input-error-desc-error-' + this.id.toString() : undefined
						}
						autoComplete={this.props.type === InputType.Date && this.props.title == 'Date de naissance' ? 'bday' : ''}
						className={'fr-input' + errorInputStr}
						type={typeStr}
						max={'9999-12-31'}
						readOnly={this.props.isReadonly || this.props.disabled}
						id={this.id}
						name={this.props.name || ''}
						required={this.props.isRequired}
						onChange={(e) => {
							if (this.props.onChange) {
								this.props.onChange(e.currentTarget.value);
							}
							if (this.props.onChangeEvent) {
								this.props.onChangeEvent(e);
							}
						}}
						disabled={this.props.disabled}
						onBlur={(e) => {
							if (this.props.onBlur) {
								this.props.onBlur(e.currentTarget.value);
							}
						}}></input>
				)}
				{this.props.errorMessage && (
					<p
						className='fr-error-text'
						id={'text-input-error-desc-error-' + this.id.toString()}>
						<ConvertHTML text={this.props.errorMessage}></ConvertHTML>
					</p>
				)}
			</>
		);
	}

	render() {
		var customInput = this.props.isCustom ? '-custom' : '';
		var disabledStr = this.props.disabled ? ' fr-input-group--disabled' : '';
		var errorLabelStr = this.props.errorMessage ? ' fr-input-group--error' : '';
		var errorInputStr = this.props.errorMessage ? ' fr-input--error' : '';
		var typeStr = 'text';
		switch (this.props.type) {
			case InputType.Text:
				typeStr = 'text';
				break;
			case InputType.Date:
				typeStr = 'date';
				break;
			case InputType.Number:
				typeStr = 'number';
				break;
			case InputType.Tel:
				typeStr = 'tel';
				break;
			case InputType.Color:
				typeStr = 'color';
				break;
			case InputType.Email:
				typeStr = 'email';
				break;
			case InputType.Password:
				typeStr = 'password';
				break;
			case InputType.Range:
				typeStr = 'range';
				break;
			case InputType.Time:
				typeStr = 'time';
				break;
			case InputType.Url:
				typeStr = 'url';
				break;
			case InputType.Week:
				typeStr = 'wange';
				break;
			case InputType.Hidden:
				typeStr = 'hidden';
				break;
		}

		return (
			<div
				className={'fr-input-group' + customInput + disabledStr + errorLabelStr}
				onClick={(e) => e.stopPropagation()}>
				{this.props.type != InputType.Hidden && (
					<>
						<label
							className='fr-label input-label'
							htmlFor={this.id}
							style={{ display: this.props.type === InputType.Password ? 'flex' : 'auto', color: '#161616' }}>
							<span className={this.props.type === InputType.Password ? 'fr-title-password' : ''}>
								{' '}
								<ConvertHTML text={this.props.title} />
							</span>
							{this.props.description && (
								<span
									className='fr-hint-text'
									style={{ color: '#161616' }}>
									{this.props.description}
								</span>
							)}
							{this.props.type === InputType.Password && !this.state.showPassword && (
								<>
									<span className='pwd-separator'></span>
									<button
										className='fr-btn fr-btn--tertiary-no-outline input-toggle-password'
										title='Afficher le mot de passe'
										onClick={this.togglePassword.bind(this)}>
										<span className='fr-icon-eye-line fr-fi--sm'></span> Afficher
									</button>
								</>
							)}
							{this.props.type === InputType.Password && this.state.showPassword && (
								<>
									<span className='pwd-separator'></span>
									<button
										className='fr-btn fr-btn--tertiary-no-outline input-toggle-password'
										title='Cacher le mot de passe'
										onClick={this.togglePassword.bind(this)}>
										<span className='fr-icon-eye-off-line fr-fi--sm'></span> Cacher
									</button>
								</>
							)}
						</label>
					</>
				)}

				{this.props.iconClass != null && (
					<div className={'fr-input-wrap ' + this.props.iconClass}>{this.buildHtml(typeStr, errorInputStr)}</div>
				)}

				{this.props.iconClass == null && this.buildHtml(typeStr, errorInputStr)}
			</div>
		);
	}
}

export enum InputType {
	Text,
	Date,
	Number,
	Color,
	Email,
	Password,
	Range,
	Time,
	Url,
	Week,
	Tel,
	TextArea,
	Hidden
}

Input.defaultProps = {
	name: ''
};

export default Input;
