import { v4 as uuid } from 'uuid';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import Modal from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import { AttachmentState } from '../../services/AttachmentService';
import DateService from '../../services/DateService';
import FieldCheckerService from '../../services/FieldCheckerService';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import { NephosESLScopPoolMilitaryEducationDetail } from '../../sparta.api';
import { BrowserView } from 'react-device-detect';
import { createRef } from 'react';

interface ICompetencesDispositifsDefenseState extends IProfileState {
	isDispositifDefenseLoading?: boolean;
	currentMilitaryPreparation?: NephosESLScopPoolMilitaryEducationDetail;
	tileLabels?: string[];
	successMessage?: string;
	isUploadCertificate: boolean;
	isDeleteCertificate: boolean;
	isUploadReportCardMilitaryPreparation: boolean;
	isDeleteReportCardMilitaryPreparation: boolean;
}

class CompetencesDispositifsDefense extends BaseComponent<ICompetencesDispositifsDefenseState> {
	state: ICompetencesDispositifsDefenseState = {
		isEdit: false,
		isUploadCertificate: false,
		isDeleteCertificate: false,
		isUploadReportCardMilitaryPreparation: false,
		isDeleteReportCardMilitaryPreparation: false
	};
	militaryPreparationModalId: string = uuid();
	currentMilitaryPreparationIndex: number = -1;
	inputRef: any = createRef();

	groupingType: number = EnumGroupingType.COMPETENCES_DISPOSITIF_DEFENSE;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.DISPOSITIF_DEFENSE_AVEZ_VOUS_FAIT_UNE_PREPARATION_MILITAIRE,
			this.state.profile?.education?.isMilitaryPrepared,
			this.requiredFields
		);
	}

	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Dispositifs défense'
							description='Veuillez remplir les informations demandées ci-dessous'
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<h5>Préparation militaire</h5>
							<p>Avez vous fait une préparation militaire ?</p>

							<Radio
								items={[
									{
										DisplayName: 'Oui',
										Value: 1,
										Checked: this.state.profile?.education?.isMilitaryPrepared
									},
									{
										DisplayName: 'Non',
										Value: 0,
										Checked: !this.state.profile?.education?.isMilitaryPrepared
									}
								]}
								disabled={this.state.groupingIsValidate ?? false}
								isHorizontal={false}
								isSmall={false}
								name='has-military-prep'
								title=''
								onChange={(e) => {
									this.setState({
										profile: {
											...this.state.profile,
											education: {
												...this.state.profile?.education,
												isMilitaryPrepared: e.currentTarget.value === '1'
											}
										}
									});
								}}></Radio>
							{this.state.profile?.education?.isMilitaryPrepared && (
								<Button
									text='Ajouter une préparation'
									disabled={this.state.groupingIsValidate ?? false}
									size={ButtonSize.Medium}
									type={ButtonType.Primary}
									modalId={this.militaryPreparationModalId}
									onClick={() => {
										this.currentMilitaryPreparationIndex = -1;
										this.setState(
											{
												isEdit: false,
												currentMilitaryPreparation: undefined,
												isDispositifDefenseLoading: true,
												isUploadCertificate: false,
												isDeleteCertificate: false,
												isUploadReportCardMilitaryPreparation: false,
												isDeleteReportCardMilitaryPreparation: false
											},
											() => {
												this.setState({
													isDispositifDefenseLoading: false
												});
											}
										);
									}}></Button>
							)}
							{!this.state.profile?.education?.isMilitaryPrepared && (
								<Button
									text='Enregistrer mes informations'
									disabled={false}
									size={ButtonSize.Medium}
									type={ButtonType.Primary}
									onClick={() => {
										if (!this.state.profile?.education?.isMilitaryPrepared) {
											this.setState(
												{
													profile: {
														...this.state.profile,
														education: {
															...this.state.profile?.education,
															militaryEducationDetails: []
														}
													}
												},
												() => {
													this.setState(
														{
															successMessage:
																'Les informations renseignées ont été enregistrées avec succès'
														},
														this.updateProfile
													);
												}
											);
										} else {
											this.setState(
												{
													successMessage:
														'Les informations renseignées ont été enregistrées avec succès'
												},
												this.updateProfile
											);
										}
									}}></Button>
							)}
							<TileContainer>
								{this.state.profile?.education?.militaryEducationDetails?.map((x, i) => {
									return (
										<Tile
											key={x.preparationId + '' + x.preparationPlace}
											description={this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'}
											title=''
											modalId={this.militaryPreparationModalId}
											disabled={this.state.groupingIsValidate ?? false}
											onLoad={() =>
												ReferentialsService.getReferential(
													ReferentialType.MILITARY_PREPARATION_TYPE,
													x.preparationId ? x.preparationId : 0
												)
											}
											onDelete={async () => {
												let isGroupingValidated = await this.checkIfCanDelete();
												if (!isGroupingValidated) {
													var militaryPreps = [
														...(this.state.profile?.education?.militaryEducationDetails || [])
													];
													militaryPreps.splice(i, 1);
													this.setState(
														{
															successMessage: 'La préparation a été supprimée avec succès',
															profile: {
																...this.state.profile,
																education: {
																	...this.state.profile?.education,
																	militaryEducationDetails: militaryPreps
																}
															}
														},
														() => {
															this.updateProfile();
														}
													);
												}
											}}
											onEdit={() => {
												this.currentMilitaryPreparationIndex = i;
												this.setState(
													{
														isEdit: true,
														currentMilitaryPreparation:
															this.state.profile?.education?.militaryEducationDetails?.at(i),
														isDispositifDefenseLoading: true,
														isUploadCertificate: false,
														isDeleteCertificate: false,
														isUploadReportCardMilitaryPreparation: false,
														isDeleteReportCardMilitaryPreparation: false
													},
													() => {
														this.setState({
															isDispositifDefenseLoading: false
														});
													}
												);
											}}></Tile>
									);
								})}
							</TileContainer>
							<Modal
								inputRef={this.inputRef}
								title={this.state.isEdit ? 'Modifier une préparation' : 'Ajouter une préparation'}
								id={this.militaryPreparationModalId}
								buttons={[
									{
										Disabled:
											this.state.currentMilitaryPreparation?.preparationId == 0 ||
											this.state.currentMilitaryPreparation?.preparationId == undefined,
										DisplayName: this.state.isEdit ? 'Modifier une préparation' : 'Ajouter une préparation',
										OnClick: () => {
											if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
												this.inputRef.current.disabled = true;
												var militaryPrep = [
													...(this.state.profile?.education?.militaryEducationDetails || [])
												];
												if (this.state.currentMilitaryPreparation) {
													if (this.currentMilitaryPreparationIndex === -1) {
														militaryPrep.push(this.state.currentMilitaryPreparation);
													} else {
														militaryPrep[this.currentMilitaryPreparationIndex] =
															this.state.currentMilitaryPreparation;
													}
												}
												this.setState(
													{
														successMessage: this.state.isEdit
															? 'La préparation a été modifié avec succès'
															: 'La préparation a été ajoutée avec succès',
														profile: {
															...this.state.profile,
															education: {
																...this.state.profile?.education,
																militaryEducationDetails: militaryPrep
															}
														},
														isUploadCertificate: false,
														isDeleteCertificate: false,
														isUploadReportCardMilitaryPreparation: false,
														isDeleteReportCardMilitaryPreparation: false
													},
													() => {
														this.updateProfile();
														setTimeout(() => {
															this.inputRef.current.disabled = false;
														}, 2000);
													}
												);
											}
										}
									}
								]}
								children={
									<>
										<ReferentialSelect
											type={ReferentialType.MILITARY_PREPARATION_TYPE}
											label='Type de préparation militaire'
											value={this.state.currentMilitaryPreparation?.preparationId}
											onChange={(e) => {
												this.setState({
													currentMilitaryPreparation: {
														...this.state.currentMilitaryPreparation,
														preparationId: e?.id
													}
												});
											}}></ReferentialSelect>
										<Input
											title='Lieu de la préparation militaire'
											type={InputType.Text}
											value={
												this.state.currentMilitaryPreparation != undefined
													? this.state.currentMilitaryPreparation?.preparationPlace
													: ''
											}
											onChange={(e) => {
												this.setState({
													currentMilitaryPreparation: {
														...this.state.currentMilitaryPreparation,
														preparationPlace: e
													}
												});
											}}></Input>
										<Input
											title="Date d'obtention du diplôme"
											type={InputType.Date}
											value={
												this.state.currentMilitaryPreparation != undefined
													? DateService.Convert(
															this.state.currentMilitaryPreparation?.graduationDate || ''
													  )
													: ''
											}
											onChange={(e) => {
												this.setState({
													currentMilitaryPreparation: {
														...this.state.currentMilitaryPreparation,
														graduationDate: DateService.Convert(e)
													}
												});
											}}></Input>
										<ReferentialSelect
											type={ReferentialType.MENTION}
											label='Mention obtenue'
											value={this.state.currentMilitaryPreparation?.award}
											onChange={(e) => {
												this.setState({
													currentMilitaryPreparation: {
														...this.state.currentMilitaryPreparation,
														award: e?.id
													}
												});
											}}></ReferentialSelect>
										{ReferentialsService.getArmy() === 2 && (
											<>
												<hr className='fr-hr'></hr>
												<AttachmentUpload
													title='Pièce jointe'
													hintText='Attestation de préparation militaire'
													isDisabled={this.state.groupingIsValidate ?? false}
													guid={this.state.currentMilitaryPreparation?.certificate?.id}
													validationState={
														this.state.currentMilitaryPreparation?.certificate?.validationState
													}
													onUpload={(guid) => {
														this.setState({
															currentMilitaryPreparation: {
																...this.state.currentMilitaryPreparation,
																certificate: {
																	id: guid,
																	validationState: AttachmentState.ADDED
																}
															},
															isUploadCertificate: true
														});
													}}
													onDelete={() => {
														this.setState({
															currentMilitaryPreparation: {
																...this.state.currentMilitaryPreparation,
																certificate: undefined
															},
															isDeleteCertificate: true
														});
													}}
													isUploadInProgress={this.state.isUploadCertificate}
													isDeleteInProgress={this.state.isDeleteCertificate}></AttachmentUpload>
											</>
										)}
										{ReferentialsService.getArmy() === 1 && (
											<>
												<hr className='fr-hr'></hr>
												<AttachmentUpload
													title='Pièce jointe'
													hintText='Relevé de notes'
													isDisabled={this.state.groupingIsValidate ?? false}
													guid={
														this.state.currentMilitaryPreparation?.reportCardMilitaryPreparation?.id
													}
													validationState={
														this.state.currentMilitaryPreparation?.reportCardMilitaryPreparation
															?.validationState
													}
													onUpload={(guid) => {
														this.setState({
															currentMilitaryPreparation: {
																...this.state.currentMilitaryPreparation,
																reportCardMilitaryPreparation: {
																	id: guid,
																	validationState: AttachmentState.ADDED
																}
															},
															isUploadReportCardMilitaryPreparation: true
														});
													}}
													onDelete={() => {
														this.setState({
															currentMilitaryPreparation: {
																...this.state.currentMilitaryPreparation,
																reportCardMilitaryPreparation: undefined
															},
															isDeleteReportCardMilitaryPreparation: true
														});
													}}
													isUploadInProgress={this.state.isUploadReportCardMilitaryPreparation}
													isDeleteInProgress={
														this.state.isDeleteReportCardMilitaryPreparation
													}></AttachmentUpload>
											</>
										)}
									</>
								}></Modal>
						</FormContainer>
					</div>
				</div>
			</Container>
		);
	}
}
export default CompetencesDispositifsDefense;
