import React from 'react';
import CardContainer from '../../components/card-container/CardContainer';
import Card from '../../components/card/Card';
import PageTitle from '../../components/page-title/PageTitle';
import Book from '../../images/pictograms/book.png';
import GroupingValidationService from '../../services/GroupingValidationService';
import EnumGroupingType from '../../enums/EnumGroupingType';

class Competences extends React.Component<{}, { groupingCards: JSX.Element[] }> {
	constructor(props: any) {
		super(props);
		this.state = {
			groupingCards: []
		};
	}

	async componentDidMount() {
		await GroupingValidationService.getGroupingValidationsList(
			localStorage.getItem('armyId') || '',
			EnumGroupingType.COMPETENCES
		).then((groupings) => {
			const groupingElements = groupings
				.sort((a, b) => {
					return a.type - b.type;
				})
				.map((grouping) => {
					return (
						<Card
							key={grouping.name}
							title={grouping.name}
							isHorizontal={true}
							tags={[GroupingValidationService.getCardTag(grouping)]}
							hasBottomArrow={true}
							linkUrl={this.getCardLink(grouping.type)}
							description={grouping.isComplete && !grouping.isValidated ? 'En cours de validation...' : ''}
							isDisabled={!grouping.isVisible}></Card>
					);
				});
			this.setState({ groupingCards: groupingElements });
		});
	}

	private getCardLink(groupingType: number) {
		const baseURL = 'competences/';
		switch (groupingType) {
			case EnumGroupingType.COMPETENCES_SCOLARITE:
				return baseURL + 'scholarship';
			case EnumGroupingType.COMPETENCES_DIPLOMES:
				return baseURL + 'diplomes-formations';
			case EnumGroupingType.COMPETENCES_EXPERIENCE_PROFESSIONNELLE:
				return baseURL + 'experiences-professionnelles';
			case EnumGroupingType.COMPETENCES_DISPOSITIF_DEFENSE:
				return baseURL + 'dispositif-defense';
			case EnumGroupingType.COMPETENCES_EXPERIENCE_MILITAIRE:
				return baseURL + 'army-experience';
			case EnumGroupingType.COMPETENCES_PERMIS:
				return baseURL + 'driving-license';
			case EnumGroupingType.COMPETENCES_LANGUES:
				return baseURL + 'languages';
			case EnumGroupingType.COMPETENCES_DIVERS:
				return baseURL + 'extras';
		}
	}

	render() {
		return (
			<div className='fr-container'>
				<div className='fr-grid-row'>
					<div className='fr-col-12'>
						<PageTitle
							title='Compétences'
							description='Vous trouverez ci-dessous les différentes sous-catégories des compétences de votre dossier de candidature'
							imgLink={Book}
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row'>
					<div className='fr-col-12'>
						<CardContainer>{this.state.groupingCards}</CardContainer>
					</div>
				</div>
			</div>
		);
	}
}

export default Competences;
