import QueryString, { ParsedQs } from 'qs';
import React, { MouseEventHandler } from 'react';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Checkbox from '../../components/checkbox/Checkbox';
import Container from '../../components/container/Container';
import Input, { InputType } from '../../components/input/Input';
import Link, { LinkSize } from '../../components/link/Link';
import LoginService, { LoginResponse } from '../../services/LoginService';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import FormContainer from '../../components/form-container/FormContainer';
import './0-Login-Mobile.scss';
import './1-Login-Desktop.scss';
import ReferentialsService from '../../services/ReferentialsService';
import AdtLogo from '../../images/army_logos/army_logo_adt.png';
import MnLogo from '../../images/army_logos/army_logo_mn.png';
import AaeLogo from '../../images/army_logos/army_logo_aae.png';
import PageTitle from '../../components/page-title/PageTitle';
import JobService from '../../services/JobService';

interface ILoginState {
	login?: string;
	password?: string;
	rememberMe?: boolean;
	isLoading: boolean;
	redirectUrl?: ParsedQs;
	loginResponse: LoginResponse;
	addJobOfInterest: boolean;
}

class Login extends React.Component<{ location: Location }> {
	state: ILoginState = {
		isLoading: false,
		login: '',
		password: '',
		loginResponse: LoginResponse.UNKNOWN,
		addJobOfInterest: false
	};

	accountCreationURL: string = '';

	constructor(props: any) {
		super(props);
		localStorage.removeItem('auth');
		const parsedQueryString = QueryString.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});
		if (parsedQueryString.redirect_url) {
			this.state.redirectUrl = parsedQueryString.redirect_url as ParsedQs;
		}

		if (parsedQueryString.army_id) {
			localStorage.setItem('armyId', parsedQueryString.army_id.toString());
		} else {
			localStorage.removeItem('armyId');
		}

		if (parsedQueryString.origin_id) {
			localStorage.setItem('originId', parsedQueryString.origin_id.toString());
		} else {
			localStorage.removeItem('originId');
		}

		this.accountCreationURL = `/accountcreation?army_id=${parsedQueryString.army_id}&origin_id=${parsedQueryString.origin_id}`;

		if (parsedQueryString.job_title) {
			localStorage.setItem('jobTitle', parsedQueryString.job_title.toString());
			this.accountCreationURL += `&job_title=${parsedQueryString.job_title}`;
		} else localStorage.removeItem('jobTitle');

		if (parsedQueryString.job_category_id) {
			localStorage.setItem('jobCategoryId', parsedQueryString.job_category_id.toString());
			this.accountCreationURL += `&job_category_id=${parsedQueryString.job_category_id}`;
		} else localStorage.removeItem('jobCategoryId');

		if (parsedQueryString.max_age) {
			localStorage.setItem('maxAge', parsedQueryString.max_age.toString());
			this.accountCreationURL += `&max_age=${parsedQueryString.max_age}`;
		} else localStorage.removeItem('maxAge');

		if (parsedQueryString.origin_url) {
			localStorage.setItem('originURL', parsedQueryString.origin_url.toString());
			this.accountCreationURL += `&origin_url=${parsedQueryString.origin_url}`;
		} else localStorage.removeItem('originURL');

		if (parsedQueryString.cv) {
			this.accountCreationURL += `&cv=${parsedQueryString.cv}`;
		}

		if (parsedQueryString.lm) {
			this.accountCreationURL += `&lm=${parsedQueryString.lm}`;
		}
	}

	async AddJobOfInterest(jobCategoryId: string, jobTitle: string, maxAge: string, resp: LoginResponse) {
		await JobService.addJobOfInterest(jobCategoryId, jobTitle, maxAge).then((result) => {
			this.setState({
				isLoading: false,
				loginResponse: resp,
				addJobOfInterest: true
			});
		});
	}

	onLogin: MouseEventHandler = async (e) => {
		this.setState({ isLoading: true });
		if (this.state.login && this.state.password) {
			LoginService.login(
				this.state.login,
				this.state.password,
				(localStorage.getItem('armyId') != 'NaN' ? localStorage.getItem('armyId') : '') || ''
			).then((resp: LoginResponse) => {
				var jobTitle = localStorage.getItem('jobTitle') || '';
				var jobCategoryId = localStorage.getItem('jobCategoryId') || '';

				if (jobTitle.length > 0 && jobCategoryId.length > 0) {
					this.AddJobOfInterest(
						localStorage.getItem('jobCategoryId') || '',
						localStorage.getItem('jobTitle') || '',
						localStorage.getItem('maxAge') || '',
						resp
					);
				} else {
					this.setState({
						isLoading: false,
						loginResponse: resp,
						addJobOfInterest: false
					});
				}
			});
		} else {
			this.setState({ isLoading: false, LoginResponse: LoginResponse.INVALID_CREDENTIALS });
		}
	};

	render() {
		if (this.state.loginResponse === LoginResponse.SUCCESS) {
			if (this.state.addJobOfInterest) {
				window.location.href = '/postes-souhaites';
			} else {
				window.location.href = this.state.redirectUrl ? this.state.redirectUrl.toString() : '/';
			}
		}
		return (
			<>
				<Container isLoading={this.state.isLoading}>
					{localStorage.getItem('jobTitle') && (
						<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
							<div
								className='fr-col-11 fr-col-lg-8'
								style={{ paddingTop: '2rem' }}>
								<PageTitle
									title={localStorage.getItem('jobTitle') || ''}
									description='Connectez-vous ou créez un compte pour postuler à cette offre'></PageTitle>
							</div>
						</div>
					)}
					<div className='fr-grid-row fr-grid-row--center'>
						<div className='fr-col-12 fr-col-lg-8'>
							<FormContainer>
								<h4>Connexion à votre espace candidat</h4>
								<h5>Se connecter avec son compte</h5>
								<div className='login-alert-container'>
									<Alert
										description='Identifiant/mot de passe incorrect.'
										size={AlertSize.Small}
										type={AlertType.Error}
										isShown={this.state.loginResponse === LoginResponse.REQUIRED_CREDENTIALS}
										hasCloseButton={true}
										onHide={() => this.setState({ loginResponse: LoginResponse.UNKNOWN })}
									/>
									<Alert
										description='Identifiant/mot de passe incorrect.'
										size={AlertSize.Small}
										type={AlertType.Error}
										isShown={this.state.loginResponse === LoginResponse.INVALID_CREDENTIALS}
										hasCloseButton={true}
										onHide={() => this.setState({ loginResponse: LoginResponse.UNKNOWN })}
									/>
									<Alert
										description='Une erreur serveur est survenue, veuillez réessayer ultérieurement.'
										size={AlertSize.Small}
										type={AlertType.Error}
										hasCloseButton={true}
										onHide={() => this.setState({ loginResponse: LoginResponse.UNKNOWN })}
										isShown={this.state.loginResponse === LoginResponse.INTERNAL_SERVER_ERROR}
									/>
								</div>
								<Input
									title='Identifiant'
									description='Format attendu : nom@domaine.fr'
									type={InputType.Text}
									value={this.state.login}
									onChange={(e) => {
										this.setState({
											login: e
										});
									}}></Input>
								<Input
									isCurrentPassword={true}
									title='Mot de passe'
									type={InputType.Password}
									value={this.state.password}
									onChange={(e) => {
										this.setState({
											password: e
										});
									}}
									isCustom={true}></Input>
								<Link
									link='/forgottenpassword'
									size={LinkSize.Small}
									title='Mot de passe oublié ?'
								/>
								<Checkbox
									items={[
										{
											Checked: this.state.rememberMe ?? false,
											DisplayName: 'Se souvenir de moi',
											Value: this.state.rememberMe ?? false
										}
									]}
									onChange={(e) => {
										this.setState({
											rememberMe: e.currentTarget.checked
										});
									}}
									></Checkbox>
								<Button
									className='mobile-button'
									size={ButtonSize.Medium}
									text='Se connecter'
									type={ButtonType.Primary}
									onClick={this.onLogin}
								/>
								<hr className='fr-hr' />
								<h4>Vous n'avez pas de compte ?</h4>
								{typeof ReferentialsService.getArmy() == 'number' &&
									!Object.is(ReferentialsService.getArmy(), NaN) && (
										<ul className='fr-btns-group'>
											<li>
												<a
													className='fr-btn fr-btn--secondary'
													href={this.accountCreationURL}>
													Créer un compte
												</a>
											</li>
										</ul>
									)}
								{!ReferentialsService.getArmy() && (
									<>
										<span>Veuillez sélectionner une armée d'intérêt</span>
										<div className='army-logo-choice-container'>
											<div className='army-logo-choice'>
												<a href='https://www.sengager.fr/'>
													<img
														src={AdtLogo}
														alt='Armée de Terre'></img>
												</a>
											</div>
											<div className='space'></div>
											<div className='army-logo-choice'>
												<a href='https://www.lamarinerecrute.fr/'>
													<img
														src={MnLogo}
														alt='Marine Nationale'></img>
												</a>
											</div>
											<div className='end-line'></div>
											<div className='army-logo-choice'>
												<a href='https://www.devenir-aviateur.fr/'>
													<img
														src={AaeLogo}
														alt="Armée de l'Air et de l'Espace"></img>
												</a>
											</div>
											<div className='space'></div>
											<div className='army-logo-choice'></div>
										</div>
									</>
								)}
							</FormContainer>
						</div>
					</div>
				</Container>
			</>
		);
	}
}

export default Login;
