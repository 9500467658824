import PageTitle from '../../components/page-title/PageTitle';
import Select, { SelectItem } from '../../components/select/Select';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import FormContainer from '../../components/form-container/FormContainer';
import Container from '../../components/container/Container';
import { BrowserView, MobileView } from 'react-device-detect';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import ReferentialsService from '../../services/ReferentialsService';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import EnumGroupingType from '../../enums/EnumGroupingType';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import FieldCheckerService from '../../services/FieldCheckerService';
import TabItem from '../../components/tabs/TabItem';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../services/AttachmentService';
import Radio from '../../components/radio/Radio';
import Checkbox from '../../components/checkbox/Checkbox';
import Tabs from '../../components/tabs/Tabs';

interface ISituationFamilialeState extends IProfileState {
	isMaritalStatusLoading: boolean;

	familyRecordBook?: File;
	isUploadFamilyRecordBook: boolean;
	isDeleteFamilyRecordBook: boolean;
	isUploadDivorceCertificate: boolean;
	isDeleteDivorceCertificate: boolean;
	isUploadMotherDeathCertificate: boolean;
	isDeleteMotherDeathCertificate: boolean;
	isUploadFatherDeathCertificate: boolean;
	isDeleteFatherDeathCertificate: boolean;
}

class SituationFamiliale extends BaseComponent<ISituationFamilialeState> {
	title: string = 'Situation familiale';
	maritalStatusesItems: SelectItem[] = [];

	constructor(props: any) {
		super(props);
		this.state = {
			isMaritalStatusLoading: true,
			isEdit: false,
			isUploadFamilyRecordBook: false,
			isDeleteFamilyRecordBook: false,
			isUploadDivorceCertificate: false,
			isDeleteDivorceCertificate: false,
			isUploadMotherDeathCertificate: false,
			isDeleteMotherDeathCertificate: false,
			isUploadFatherDeathCertificate: false,
			isDeleteFatherDeathCertificate: false
		};
	}

	groupingType: number = EnumGroupingType.INFORMATIONS_PERSONNELLES_SITUATION_FAMILLIALE;

	async componentDidMount() {
		document.title = this.title;
		super.componentDidMount();

		await ReferentialsService.getMaritalStatuses().then((maritalStatuses) => {
			if (maritalStatuses) {
				var referentielItems = [{ id: 0, name: 'Sélectionnez', isActive: true }, ...maritalStatuses];
				this.maritalStatusesItems = referentielItems.map((x) => {
					return { DisplayName: x.name, Value: x.id };
				});
				this.setState({ isMaritalStatusLoading: false });
			}
		});
	}

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.SITUATION_FAMILIALE_SITATION_MATRIMONIALE,
			this.state.profile?.personal?.familiyStatus?.maritalStatusId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.SITUATION_FAMILIALE_NOMBRE_D_ENFANTS,
			this.state.profile?.personal?.familiyStatus?.numberOfChildren,
			this.requiredFields
		);
	}

	handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const needAttachment = event.currentTarget.value === '1';
		this.setState((prevState) => ({
			profile: {
				...this.state.profile,
				personal: {
					...this.state.profile?.personal,
					familiyStatus: {
						...this.state.profile?.personal?.familiyStatus,
						deceasedParents: needAttachment
					}
				}
			}
		}));
	};

	initUpload() {
		this.setState({
			isUploadFamilyRecordBook: false,
			isDeleteFamilyRecordBook: false,
			isUploadDivorceCertificate: false,
			isDeleteDivorceCertificate: false,
			isUploadMotherDeathCertificate: false,
			isDeleteMotherDeathCertificate: false,
			isUploadFatherDeathCertificate: false,
			isDeleteFatherDeathCertificate: false
		});
	}

	render() {
		var selectItemsChildren: SelectItem[] = [
			{
				DisplayName: 'Sélectionnez',
				Value: 0
			},
			{
				DisplayName: '0',
				Value: 1
			},
			{
				DisplayName: '1',
				Value: 2
			},
			{
				DisplayName: '2',
				Value: 3
			},
			{
				DisplayName: '3 ou plus',
				Value: 4
			}
		];
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Situation familiale'
							description='Veuillez remplir les champs d’informations ci-dessous'
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							mobileOnly={true}
							isFormContainer={true}
							items={[
								{
									displayName: 'Formulaire',
									id: 'form',
									tabIndex: 0,
									selected: true
								},
								{
									displayName: 'Pièces jointes',
									id: 'attachments',
									tabIndex: 1,
									selected: false
								}
							]}>
							<TabItem
								id='form'
								tabIndex={0}
								selected={true}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										{!this.state.isMaritalStatusLoading && (
											<Select
												defaultOptionDisplayName='Selectionnez'
												description=''
												items={this.maritalStatusesItems}
												title='Situation matrimoniale'
												errorMessage=''
												disabled={this.state.groupingIsValidate ?? false}
												value={this.state.profile?.personal?.familiyStatus?.maritalStatusId}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																familiyStatus: {
																	...this.state.profile?.personal?.familiyStatus,
																	maritalStatusId: +e
																}
															}
														}
													})
												}></Select>
										)}
									</div>
								</div>
								{ReferentialsService.getArmy() === 1 && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Select
													defaultOptionDisplayName='Selectionnez'
													description=''
													items={selectItemsChildren}
													title='Nombre d’enfants'
													errorMessage=''
													disabled={this.state.groupingIsValidate ?? false}
													value={this.state.profile?.personal?.familiyStatus?.numberOfChildren}
													onChange={(e) =>
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	familiyStatus: {
																		...this.state.profile?.personal?.familiyStatus,
																		numberOfChildren: +e
																	}
																}
															}
														})
													}></Select>
											</div>
										</div>
									</>
								)}
								{ReferentialsService.getArmy() === 2 && (
									<>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<h5>Parents décédés</h5>
												<Radio
													name='deceasedParents'
													title='Avez-vous un parent décédé ?'
													isHorizontal={false}
													items={[
														{
															DisplayName: 'Oui',
															Value: 1,
															Checked:
																this.state.profile?.personal?.familiyStatus?.deceasedParents ===
																true
														},
														{
															DisplayName: 'Non',
															Value: 0,
															Checked:
																this.state.profile?.personal?.familiyStatus?.deceasedParents ===
																false
														}
													]}
													disabled={this.state.groupingIsValidate ?? false}
													isSmall={false}
													errorMessage=''
													onChange={this.handleChange}
												/>
											</div>
										</div>
										{this.state.profile?.personal?.familiyStatus?.deceasedParents && (
											<div className='fr-grid-row'>
												<div className='fr-col-12'>
													<Checkbox
														isHorizontal={true}
														items={[
															{
																DisplayName: 'Mère décédée',
																Value: 1,
																Checked:
																	this.state.profile?.personal?.familiyStatus?.deceasedMother
															},
															{
																DisplayName: 'Père décédé',
																Value: 2,
																Checked:
																	this.state.profile?.personal?.familiyStatus?.deceasedFather
															}
														]}
														disabled={this.state.groupingIsValidate ?? false}
														onChange={(e) => {
															if (e.currentTarget.value === '1')
																this.setState({
																	profile: {
																		...this.state.profile,
																		personal: {
																			...this.state.profile?.personal,
																			familiyStatus: {
																				...this.state.profile?.personal?.familiyStatus,
																				deceasedMother:
																					!this.state.profile?.personal?.familiyStatus
																						?.deceasedMother
																			}
																		}
																	}
																});
															else
																this.setState({
																	profile: {
																		...this.state.profile,
																		personal: {
																			...this.state.profile?.personal,
																			familiyStatus: {
																				...this.state.profile?.personal?.familiyStatus,
																				deceasedFather:
																					!this.state.profile?.personal?.familiyStatus
																						?.deceasedFather
																			}
																		}
																	}
																});
														}}></Checkbox>
												</div>
											</div>
										)}
									</>
								)}
							</TabItem>
							{ReferentialsService.getArmy() === 2 && (
								<>
									<BrowserView>
										<hr className='fr-hr desktop-only' />
										<h5 className='desktop-only'>Pièces jointes</h5>
									</BrowserView>
									<TabItem
										id='attachments'
										tabIndex={1}
										selected={false}
										mobileOnly={true}>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
												<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<AttachmentUpload
													noExplanation
													hintText='Livret de famille'
													isDisabled={this.state.groupingIsValidate ?? false}
													guid={this.state.profile?.personal?.familiyStatus?.familyRecordBook?.id}
													validationState={
														this.state.profile?.personal?.familiyStatus?.familyRecordBook
															?.validationState
													}
													onUpload={(guid) => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	familiyStatus: {
																		...this.state.profile?.personal?.familiyStatus,
																		familyRecordBook: {
																			id: guid,
																			validationState: AttachmentState.ADDED
																		}
																	}
																}
															},
															isUploadFamilyRecordBook: true
														});
													}}
													onDelete={() => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	familiyStatus: {
																		...this.state.profile?.personal?.familiyStatus,
																		familyRecordBook: undefined
																	}
																}
															},
															isDeleteFamilyRecordBook: true
														});
													}}
													isUploadInProgress={this.state.isUploadFamilyRecordBook}
													isDeleteInProgress={this.state.isDeleteFamilyRecordBook}
												/>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<AttachmentUpload
													noExplanation
													hintText='Jugement de divorce'
													isDisabled={this.state.groupingIsValidate ?? false}
													guid={this.state.profile?.personal?.familiyStatus?.divorceCertificate?.id}
													validationState={
														this.state.profile?.personal?.familiyStatus?.divorceCertificate
															?.validationState
													}
													onUpload={(guid) => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	familiyStatus: {
																		...this.state.profile?.personal?.familiyStatus,
																		divorceCertificate: {
																			id: guid,
																			validationState: AttachmentState.ADDED
																		}
																	}
																}
															},
															isUploadDivorceCertificate: true
														});
													}}
													onDelete={() => {
														this.setState({
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	familiyStatus: {
																		...this.state.profile?.personal?.familiyStatus,
																		divorceCertificate: undefined
																	}
																}
															},
															isDeleteDivorceCertificate: true
														});
													}}
													isUploadInProgress={this.state.isUploadDivorceCertificate}
													isDeleteInProgress={this.state.isDeleteDivorceCertificate}
												/>
											</div>
										</div>
										{this.state.profile?.personal?.familiyStatus?.deceasedParents &&
											this.state.profile?.personal?.familiyStatus?.deceasedMother && (
												<div className='fr-grid-row'>
													<div className='fr-col-12'>
														<AttachmentUpload
															noExplanation
															hintText='Certificat de décès de la mère'
															isDisabled={this.state.groupingIsValidate ?? false}
															guid={
																this.state.profile?.personal?.familiyStatus
																	?.motherDeathCertificate?.id
															}
															validationState={
																this.state.profile?.personal?.familiyStatus
																	?.motherDeathCertificate?.validationState
															}
															onUpload={(guid) => {
																this.setState({
																	profile: {
																		...this.state.profile,
																		personal: {
																			...this.state.profile?.personal,
																			familiyStatus: {
																				...this.state.profile?.personal?.familiyStatus,
																				motherDeathCertificate: {
																					id: guid,
																					validationState: AttachmentState.ADDED
																				}
																			}
																		}
																	},
																	isUploadMotherDeathCertificate: true
																});
															}}
															onDelete={() => {
																this.setState({
																	profile: {
																		...this.state.profile,
																		personal: {
																			...this.state.profile?.personal,
																			familiyStatus: {
																				...this.state.profile?.personal?.familiyStatus,
																				motherDeathCertificate: undefined
																			}
																		}
																	},
																	isDeleteMotherDeathCertificate: true
																});
															}}
															isUploadInProgress={this.state.isUploadMotherDeathCertificate}
															isDeleteInProgress={this.state.isDeleteMotherDeathCertificate}
														/>
													</div>
												</div>
											)}
										{this.state.profile?.personal?.familiyStatus?.deceasedParents &&
											this.state.profile?.personal?.familiyStatus?.deceasedFather && (
												<div className='fr-grid-row'>
													<div className='fr-col-12'>
														<AttachmentUpload
															noExplanation
															hintText='Certificat de décès du père'
															isDisabled={this.state.groupingIsValidate ?? false}
															guid={
																this.state.profile?.personal?.familiyStatus
																	?.fatherDeathCertificate?.id
															}
															validationState={
																this.state.profile?.personal?.familiyStatus
																	?.fatherDeathCertificate?.validationState
															}
															onUpload={(guid) => {
																this.setState({
																	profile: {
																		...this.state.profile,
																		personal: {
																			...this.state.profile?.personal,
																			familiyStatus: {
																				...this.state.profile?.personal?.familiyStatus,
																				fatherDeathCertificate: {
																					id: guid,
																					validationState: AttachmentState.ADDED
																				}
																			}
																		}
																	},
																	isUploadFatherDeathCertificate: true
																});
															}}
															onDelete={() => {
																this.setState({
																	profile: {
																		...this.state.profile,
																		personal: {
																			...this.state.profile?.personal,
																			familiyStatus: {
																				...this.state.profile?.personal?.familiyStatus,
																				motherDeathCertificate: undefined
																			}
																		}
																	},
																	isDeleteFatherDeathCertificate: true
																});
															}}
															isUploadInProgress={this.state.isUploadFatherDeathCertificate}
															isDeleteInProgress={this.state.isDeleteFatherDeathCertificate}
														/>
													</div>
												</div>
											)}
									</TabItem>
								</>
							)}
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												className='no-margin'
												disabled={false}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												iconClassName=''
												size={ButtonSize.Medium}
												modalId=''
												onClick={() => {
													this.updateProfile(() => {
														this.displaySuccess();
														this.initUpload();
													});
												}}></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''
											onClick={() => {
												this.updateProfile(() => {
													this.displaySuccess();
													this.initUpload();
												});
											}}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}

export default SituationFamiliale;
