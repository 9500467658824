import './0-PreferenceCard-mobile.scss';
import './1-PreferenceCard-desktop.scss';
import React from 'react';

class PreferenceCard extends React.Component<
	{
		titleName?: string;
		modalId?: string;
		imgUrl?: string;
		description?: string;
	},
	{}
> {
	render() {
		return (
			<div className='fr-col-12 fr-col-sm-4 fr-col-m-4 fr-col-lg-4 fr-col-xl-4'>
				<div className='fr-tile fr-enlarge-link fr-tile--horizontal'>
					<div
						className='fr-tile__body'
						aria-controls={this.props.modalId}
						data-fr-opened='false'>
						<h4 className='fr-tile__title'>
							<button
								className='fr-tile__link'
								style={{ color: 'black', fontWeight: 700, padding: 0 }}>
								{this.props.titleName}
							</button>
						</h4>
						<p className='fr-tile__desc'>{this.props.description}</p>
					</div>
					<div className='fr-tile__img'>
						<img
							className='fr-responsive-img'
							alt=''
							src={this.props.imgUrl}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default PreferenceCard;
