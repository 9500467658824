import Input, { InputType } from '../../input/Input';
import { DateService } from '../../../services';
import { AppointmentStepProps } from '../../../models/ComponentStepProps';

function AgeStep({ model, handleChange }: AppointmentStepProps) {
	return (
		<div>
			<h3 className='fr-stepper__title'>Renseigner votre date de naissance</h3>
			<p>
				Vous devez avoir entre {model.army?.minAge} et {model.army?.maxAge} ans pour pouvoir vous engager dans{' '}
				{model.army?.name}
			</p>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Input
				title='Date de naissance'
				name='birthDate'
				type={InputType.Date}
				value={DateService.Convert(model.birthDate ?? '')}
				onChangeEvent={(e) => handleChange(e)}
				errorMessage={
					model.birthDate !== undefined &&
					model.birthDate !== '' &&
					(DateService.getAgeFromString(model.birthDate) < model.army?.minAge ||
						DateService.getAgeFromString(model.birthDate) > model.army.maxAge)
						? `Vous devez avoir entre ${model.army?.minAge} et ${model.army?.maxAge} ans pour pouvoir vous engager dans ${model.army?.name}`
						: ''
				}></Input>
		</div>
	);
}

export default AgeStep;
