import { useEffect, useState } from 'react';
import Input, { InputType } from '../../input/Input';
import { AccountPublicService, FormatCheckService } from '../../../services';
import { AppointmentStepProps } from '../../../models/ComponentStepProps';
import EventCustomer from '../../../models/EventCustomer';

function PersonnalInformationRDVStep({ model, handleChange }: AppointmentStepProps) {
	const [lastnameErrorMessage, setlastnameErrorMessage] = useState('');
	const [firstnameErrorMessage, setfirstnameErrorMessage] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	useEffect(() => {
		setlastnameErrorMessage(
			model.lastname !== '' && !FormatCheckService.checkLastName(model.lastname) ? `Le champ Nom n’est pas valide.` : ''
		);
	}, [model.lastname]);

	useEffect(() => {
		setfirstnameErrorMessage(
			model.firstname !== '' && !FormatCheckService.checkFirstName(model.firstname)
				? `Le champ Prénom n’est pas valide.`
				: ''
		);
	}, [model.firstname]);

	useEffect(() => {
		if (model.email === undefined || model.email === '') {
			setEmailErrorMessage('');
		} else if (model.email !== undefined && model.email !== '' && FormatCheckService.checkEmail(model.email)) {
			AccountPublicService.checkIfUserExist(model.email!, true).then(
				(resp: { hasApplicantAccount: boolean; errorMessage?: string; hasSpartaAccount?: boolean }) => {
					setEmailErrorMessage(!resp.hasApplicantAccount ? '' : resp.errorMessage ? resp.errorMessage : '');
					let eventHasSpartaAccount: EventCustomer = { name: 'hasSpartaAccount', value: resp.hasSpartaAccount };
					let eventHasApplicantAccount: EventCustomer = {
						name: 'hasApplicantAccount',
						value: resp.hasApplicantAccount
					};
					handleChange(eventHasSpartaAccount);
					handleChange(eventHasApplicantAccount);
				}
			);
		} else {
			setEmailErrorMessage(
				`Le format de l\’adresse électronique saisie n’est pas valide. Le format attendu est : nom@exemple.org`
			);
		}
	}, [model.email]);

	return (
		<div>
			<h3 className='fr-stepper__title'>Prendre rendez-vous en renseignant vos informations personnelles</h3>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Input
				title='Nom'
				name='lastname'
				type={InputType.Text}
				value={model.lastname || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={lastnameErrorMessage}
			/>
			<Input
				title='Prénom'
				name='firstname'
				type={InputType.Text}
				value={model.firstname || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={firstnameErrorMessage}
			/>
			<Input
				title='Adresse électronique'
				description='Format attendu : nom@domaine.fr'
				name='email'
				type={InputType.Text}
				value={model.email || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={emailErrorMessage}
			/>
		</div>
	);
}

export default PersonnalInformationRDVStep;
