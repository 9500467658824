import React from 'react';
import Padlock from '../../images/pictograms/padlock.png';
import Card from '../../components/card/Card';
import CardContainer from '../../components/card-container/CardContainer';
import PageTitle from '../../components/page-title/PageTitle';
import EnumGroupingType from '../../enums/EnumGroupingType';
import GroupingValidationService from '../../services/GroupingValidationService';

class Securite extends React.Component<{}, { groupingCards: JSX.Element[] }> {
	constructor(props: any) {
		super(props);
		this.state = {
			groupingCards: []
		};
	}

	async componentDidMount() {
		await GroupingValidationService.getGroupingValidationsList(
			localStorage.getItem('armyId') || '',
			EnumGroupingType.SECURITE
		).then((groupings) => {
			const groupingElements = groupings
				.sort((a, b) => {
					return a.type - b.type;
				})
				.map((grouping) => {
					return (
						<Card
							key={grouping.name}
							title={grouping.name}
							isHorizontal={true}
							tags={[GroupingValidationService.getCardTag(grouping)]}
							hasBottomArrow={true}
							linkUrl={this.getCardLink(grouping.type)}
							description={grouping.isComplete && !grouping.isValidated ? 'En cours de validation...' : ''}
							isDisabled={!grouping.isVisible}></Card>
					);
				});
			this.setState({ groupingCards: groupingElements });
		});
	}

	private getCardLink(groupingType: number) {
		const baseURL = 'securite/';
		switch (groupingType) {
			case EnumGroupingType.SECURITE_RENSEIGNEMENTS:
				return baseURL + 'renseignements';
			case EnumGroupingType.SECURITE_LIEU_NAISSANCE:
				return baseURL + 'lieu-naissance';
			case EnumGroupingType.SECURITE_NATIONALITE:
				return baseURL + 'nationalite';
			case EnumGroupingType.SECURITE_DOMICILE_ACTUEL:
				return baseURL + 'domicile-actuel';
			case EnumGroupingType.SECURITE_DOMICILE_PRECEDENT:
				return baseURL + 'domicile-precedent';
			case EnumGroupingType.SECURITE_RENSEIGNEMENTS_FAMILLE:
				return baseURL + 'renseignements-famille';
			case EnumGroupingType.SECURITE_ENVIRONNEMENT_NUMERIQUE:
				return baseURL + 'environnement-numerique';
		}
	}

	render() {
		return (
			<div className='fr-container'>
				<div className='fr-grid-row'>
					<PageTitle
						imgLink={Padlock}
						title='Sécurité'
						description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations et compléter les informations manquantes.'
						size='h1'
					/>
				</div>
				<div className='fr-grid-row'>
					<div className='fr-col-12'>
						<CardContainer>{this.state.groupingCards}</CardContainer>
					</div>
				</div>
			</div>
		);
	}
}

export default Securite;
