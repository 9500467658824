import React from 'react';
import Avatar from '../../images/pictograms/avatar.png';
import Card from '../../components/card/Card';
import { LabelType } from '../../components/label/Label';
import CardContainer from '../../components/card-container/CardContainer';
import PageTitle from '../../components/page-title/PageTitle';
import GroupingValidationService from '../../services/GroupingValidationService';
import EnumGroupingType from '../../enums/EnumGroupingType';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import DateService from '../../services/DateService';

interface IInfosPersonnellesState extends IProfileState {
	groupingCards: JSX.Element[];
}

class InfosPersonnelles extends BaseComponent<IInfosPersonnellesState, {}> {
	groupingType: number = 100;
	constructor(props: any) {
		super(props);
		this.state = {
			groupingCards: [],
			isEdit: false
		};
	}

	async componentDidMount() {
		await super.componentDidMount().then(async () => {
			await GroupingValidationService.getGroupingValidationsList(
				localStorage.getItem('armyId') || '',
				EnumGroupingType.INFORMATIONS_PERSONNELLES
			).then((groupings) => {
				var groupingsTrimmed = [...groupings];
				var repLegalGrouping = groupingsTrimmed.find(
					(grouping) => grouping.type === EnumGroupingType.INFORMATIONS_PERSONNELLES_REPRESENTANT_LEGAL
				);
				var repLegalGroupingIndex = -1;

				if (repLegalGrouping) repLegalGroupingIndex = groupingsTrimmed.indexOf(repLegalGrouping);

				if (DateService.getAgeFromString(this.state.profile?.personal?.identity?.dateOfBirth || '') >= 18) {
					groupingsTrimmed.splice(repLegalGroupingIndex, 1);
				}

				const groupingElements = groupingsTrimmed.map((grouping) => {
					return (
						<Card
							key={grouping.name}
							title={grouping.name}
							isHorizontal={true}
							tags={[GroupingValidationService.getCardTag(grouping)]}
							hasBottomArrow={true}
							description={grouping.isComplete && !grouping.isValidated ? 'En cours de validation...' : ''}
							linkUrl={this.getCardLink(grouping.type)}
							isDisabled={!grouping.isVisible}></Card>
					);
				});
				this.setState({ groupingCards: groupingElements });
			});
		});
	}

	private getCardLink(groupingType: number) {
		const baseURL = 'infos-perso/';
		switch (groupingType) {
			case EnumGroupingType.INFORMATIONS_PERSONNELLES_ETAT_CIVIL:
				return baseURL + 'maritalstatus';
			case EnumGroupingType.INFORMATIONS_PERSONNELLES_CONTACT:
				return baseURL + 'contact';
			case EnumGroupingType.INFORMATIONS_PERSONNELLES_SITUATION_FAMILLIALE:
				return baseURL + 'situationfamiliale';
			case EnumGroupingType.INFORMATIONS_PERSONNELLES_REPRESENTANT_LEGAL:
				return baseURL + 'representantlegal';
		}
	}

	render() {
		return (
			<div className='fr-container'>
				<div className='fr-grid-row'>
					<PageTitle
						imgLink={Avatar}
						title='Informations personnelles'
						description='Vous trouverez ci-dessous les différentes sous-catégories de vos informations personnelles'
						size='h1'
					/>
				</div>
				<div className='fr-grid-row'>
					<div className='fr-col-12'>
						{this.state.groupingCards && <CardContainer>{this.state.groupingCards}</CardContainer>}
					</div>
				</div>
			</div>
		);
	}
}

export default InfosPersonnelles;
