import React from 'react';

import './1-Tile-desktop.scss';

class Tile extends React.Component<
	{
		title: string;
		description: string;
		onEdit?: () => void;
		onDelete?: () => void;
		modalId?: string;
		onLoad?: () => Promise<any>;
		disabled?: boolean;
	},
	{ asyncTitle?: string }
> {
	state: any = {};

	componentDidMount() {
		if (!this.props.title && this.props.onLoad && !this.state.asyncTitle) {
			this.props.onLoad().then((x) => {
				this.setState({ asyncTitle: x?.data?.name });
			});
		}
	}

	getbutton(isdisabled: boolean) {
		if (isdisabled) {
			return (
				<span>
					<a
						className='fr-link undefined fr-link--icon-right naked'
						style={{ marginRight: '1rem' }}>
						Modifier
					</a>
					<a className='fr-link undefined fr-link--icon-right naked'>Supprimer</a>
				</span>
			);
		} else {
			return (
				<span>
					<button
						aria-controls={this.props.modalId}
						className='fr-link'
						onClick={this.props.onEdit}
						data-fr-opened='false'
						style={{ marginRight: '1rem' }}>
						Modifier
					</button>
					<button
						className='fr-link'
						onClick={this.props.onDelete}>
						Supprimer
					</button>
				</span>
			);
		}
	}

	render(): React.ReactNode {
		return (
			<div className='fr-card tile'>
				<div className='fr-card__body'>
					<div className='fr-card__content'>
						<h4 className='fr-card__title'>{this.props.title || this.state.asyncTitle}</h4>
						<p className='fr-card__desc'>{this.props.description}</p>
					</div>
				</div>
				<div className='fr-card__footer'>
					<ul className='fr-links-group'>{this.getbutton(this.props.disabled ?? false)}</ul>
				</div>
			</div>
		);
	}
}

export default Tile;
