import './0-FormulaireProspect-Mobile.scss';
import React from 'react';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import PageTitle from '../../components/page-title/PageTitle';
import Input, { InputType } from '../../components/input/Input';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import FormatCheckService from '../../services/FormatCheckService';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import QueryString from 'qs';
import AccountPublicService, { ProspectCreationStatus } from '../../services/public/AccountPublicService';
import DateService from '../../services/DateService';
import Checkbox from '../../components/checkbox/Checkbox';

interface IFormulaireProspectState {
	// Champs visibles
	firstName?: string;
	lastName?: string;
	birthday?: string;
	phoneNumber?: string;
	email?: string;
	zipCode?: string;
	lastDegreeLevelId?: number;
	desiredJobDomainId?: number;
	observations?: string;

	// Champs non-visibles
	originMediaId?: number;
	tags?: string;
	armyId?: number;
	recruiterEmail?: string;

	// Erreurs
	firstNameError?: string;
	lastNameError?: string;
	emailError?: string;
	zipCodeError?: string;
	phoneNumberError?: string;
	birthdayError?: string;

	// Validité du formulaire
	isValid?: boolean;

	// Validation du formulaire
	isLoading?: boolean;
	isSuccess?: boolean;

	// Erreurs de retour de l'API
	duplicateErrorCallBack?: boolean;
	interServerErrorCallBack?: boolean;
	noCirfaFoundErrorCallBack?: boolean;

	// Acceptation
	hasAccepted?: boolean;
}

class FormulaireProspect extends React.Component<{ location: Location }, IFormulaireProspectState> {
	army: any;
	constructor(props: any) {
		super(props);
		this.state = {};
		this.createProspect = this.createProspect.bind(this);
	}

	componentDidMount() {
		document.title = 'Espace Candidat - Formulaire prospect';
		const parsedQueryString = QueryString.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});

		if (parsedQueryString.army_id) {
			localStorage.setItem('armyId', parsedQueryString.army_id.toString());
			this.setState(
				{
					armyId: +parsedQueryString.army_id.toString()
				},
				() => {
					ReferentialsService.getReferential(ReferentialType.ARMY_PUBLIC, +(this.state.armyId || '')).then((x) => {
						var resp = { ...x.data };
						resp.name = (resp.name as string).toLowerCase();
						if (resp.name[0] === 'a') {
							resp.name = "l'" + resp.name;
						} else {
							resp.name = 'la ' + resp.name;
						}

						this.army = resp;
					});
				}
			);
		}
		if (parsedQueryString.email) {
			this.setState({
				recruiterEmail: parsedQueryString.email.toString()
			});
		}
		if (parsedQueryString.origin_media_id) {
			this.setState({
				originMediaId: +parsedQueryString.origin_media_id.toString()
			});
		}
		if (parsedQueryString.tags) {
			this.setState({
				tags: parsedQueryString.tags.toString()
			});
		}
	}

	private isValid() {
		this.setState({
			isValid:
				!this.state.firstNameError &&
				!this.state.lastNameError &&
				!this.state.emailError &&
				!this.state.phoneNumberError &&
				!this.state.zipCodeError &&
				!this.state.birthdayError &&
				this.state.firstName != undefined &&
				this.state.lastName != undefined &&
				this.state.birthday != undefined &&
				this.state.phoneNumber != undefined &&
				this.state.email != undefined &&
				this.state.zipCode != undefined &&
				this.state.hasAccepted == true
		});
	}

	private createProspect() {
		this.setState(
			{ isLoading: true, duplicateErrorCallBack: false, noCirfaFoundErrorCallBack: false, interServerErrorCallBack: false },
			() => {
				AccountPublicService.createProspect(this.state).then((resp: ProspectCreationStatus) => {
					if (resp === ProspectCreationStatus.SUCCESS) {
						this.setState({ isSuccess: true, isLoading: false });
					} else {
						this.setState({ isLoading: false }, () => {
							switch (resp) {
								case ProspectCreationStatus.DUPLICATE:
									this.setState({ duplicateErrorCallBack: true });
									break;
								case ProspectCreationStatus.NO_CIRFA_FOUND:
									this.setState({ noCirfaFoundErrorCallBack: true });
									break;
								default:
									this.setState({ interServerErrorCallBack: true });
									break;
							}
						});
					}
				});
			}
		);
	}

	render() {
		return (
			<>
				<Container isLoading={this.state.isLoading}>
					<div className='fr-grid-row fr-grid-row--center'>
						<div
							className='fr-col-12 fr-col-lg-8'
							style={{ marginTop: '2rem' }}>
							<PageTitle
								title={
									!this.state.isSuccess
										? 'Vous souhaitez en savoir plus sur nos métiers ?'
										: 'Confirmation de l’ajout du prospect'
								}
								description={!this.state.isSuccess ? 'Complétez le formulaire ci-dessous.' : ''}
							/>
							<FormContainer>
								{this.state.isSuccess && (
									<>
										<Alert
											description='<b>Un récapitulatif a été envoyé par courriel</b> à l’adresse du recruteur renseignée dans le formulaire.'
											type={AlertType.Success}
											isShown={true}
										/>
										<hr />
									</>
								)}
								{!this.state.isSuccess && (
									<>
										<Alert
											isShown={this.state.duplicateErrorCallBack}
											description='Ce prospect existe déjà dans la base'
											type={AlertType.Error}
											size={AlertSize.Small}
										/>
										<Alert
											isShown={this.state.interServerErrorCallBack}
											description='Une erreur s’est produite à la validation du formulaire'
											type={AlertType.Error}
											size={AlertSize.Small}
										/>
										<Alert
											isShown={this.state.noCirfaFoundErrorCallBack}
											description='Aucun cirfa trouvé pour le code postal renseigné. Veuillez vérifier et modifier le code postal'
											type={AlertType.Error}
											size={AlertSize.Small}
										/>
										<p></p>
										<h5>Informations personnelles</h5>
										<p className='fr-hint-text'>Sauf mention contraire, tous les champs sont obligatoires.</p>
										<Input
											title='Nom'
											type={InputType.Text}
											value={this.state.lastName}
											onChange={(e) => {
												var isValid = FormatCheckService.checkLastName(e);
												this.setState(
													{
														lastName: e,
														lastNameError: isValid == false ? 'Le format du nom est erroné' : ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.lastNameError}
										/>
										<Input
											title='Prénom'
											type={InputType.Text}
											value={this.state.firstName}
											onChange={(e) => {
												var isValid = FormatCheckService.checkFirstName(e);
												this.setState(
													{
														firstName: e,
														firstNameError: isValid == false ? 'Le format du prénom est erroné' : ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.firstNameError}
										/>
										<Input
											title='Date de naissance'
											description=''
											value={DateService.Convert(this.state.birthday || '') || ''}
											type={InputType.Date}
											onChange={(e) => {
												this.setState(
													{
														birthday: e,
														birthdayError:
															this.state.birthday &&
															DateService.getAgeFromString(this.state.birthday) < this.army?.minAge
																? `Vous devez avoir plus de ${this.army?.minAge} ans pour vous engager dans ${this.army?.name}.`
																: this.state.birthday &&
																    DateService.getAgeFromString(this.state.birthday) >
																			+(localStorage.getItem('maxAge') || this.army?.maxAge)
																  ? "Votre âge ne vous permet plus de vous engager comme soldat, mais vous pouvez encore devenir réserviste en appelant votre CIRFA ou entrer dans <a href='https://www.legion-recrute.com/fr' title='La légion étrangère' target='_blank' rel='noopener'>la légion étrangère</a>"
																  : ''
													},
													() => {
														if (
															this.state.birthday &&
															DateService.getAgeFromString(this.state.birthday) < this.army?.minAge
														) {
															this.setState({
																birthdayError: `Vous devez avoir plus de ${this.army?.minAge} ans pour vous engager dans ${this.army?.name}.`
															});
														} else if (
															this.state.birthday &&
															DateService.getAgeFromString(this.state.birthday) >
																+(localStorage.getItem('maxAge') || this.army?.maxAge)
														) {
															this.setState({
																birthdayError:
																	"Votre âge ne vous permet plus de vous engager comme soldat, mais vous pouvez encore devenir réserviste en appelant votre CIRFA ou entrer dans <a href='https://www.legion-recrute.com/fr' title='La légion étrangère' target='_blank' rel='noopener'>la légion étrangère</a>"
															});
														} else {
															this.setState(
																{
																	birthdayError: ''
																},
																() => {
																	this.isValid();
																}
															);
														}
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.birthdayError}></Input>
										<Input
											title='Numéro de téléphone'
											description='Format attendu : (+33)122334455'
											type={InputType.Tel}
											value={this.state.phoneNumber}
											onChange={(e) => {
												var isValid = FormatCheckService.checkPhoneNumber(e);
												this.setState(
													{
														phoneNumber: e,
														phoneNumberError:
															isValid == false ? 'Le format du numéro de téléphone est erroné' : ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.phoneNumberError}
										/>
										<Input
											title='Adresse électronique du prospect'
											description='Format attendu : nom@domaine.fr'
											type={InputType.Text}
											value={this.state.email}
											onChange={(e) => {
												var isValid = FormatCheckService.checkEmail(e);
												this.setState(
													{
														email: e,
														emailError:
															isValid == false
																? 'Le format de l’adresse électronique est erroné'
																: ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.emailError}
										/>
										<Input
											title='Code postal'
											type={InputType.Text}
											value={this.state.zipCode}
											onChange={(e) => {
												var isValid = FormatCheckService.checkZipCode(e);
												this.setState(
													{
														zipCode: e,
														zipCodeError:
															isValid == false ? 'Le format du code postal est erroné' : ''
													},
													() => {
														this.isValid();
													}
												);
											}}
											errorMessage={this.state.zipCodeError}
										/>
										<hr className='fr-hr'></hr>
										<h5>Informations complémentaires</h5>
										<p>Sauf mention contraire, tous les champs sont obligatoires</p>
										<ReferentialSelect
											label='Niveau du dernier diplôme obtenu (facultatif)'
											type={ReferentialType.DEGREE_PUBLIC}
											value={this.state.lastDegreeLevelId}
											onChange={(e) => {
												this.setState({
													lastDegreeLevelId: e?.id
												});
											}}
										/>
										<ReferentialSelect
											label='Domaine d’emploi souhaité (facultatif)'
											type={ReferentialType.BUSINESS_DOMAIN_PUBLIC}
											value={this.state.desiredJobDomainId}
											onChange={(e) => {
												this.setState({
													desiredJobDomainId: e?.id
												});
											}}
										/>
										<Input
											title='Observations (facultatif)'
											type={InputType.TextArea}
											value={this.state.observations}
											onChange={(e) => {
												this.setState({ observations: e });
											}}
										/>
										<Checkbox
											name='acceptance'
											items={[
												{
													Checked: this.state.hasAccepted ?? false,
													DisplayName: `En cochant cette case, vous acceptez les conditions d'utilisation et le RGPD`,
													Value: this.state.hasAccepted ?? false
												}
											]}
											onChange={(e) => {
												this.setState(
													{
														hasAccepted: e.currentTarget.checked
													},
													() => {
														this.isValid();
													}
												);
											}}
										/>
										<Button
											text='Valider'
											type={ButtonType.Primary}
											size={ButtonSize.Medium}
											className='mobile-button'
											disabled={!this.state.isValid}
											onClick={this.createProspect}
										/>
									</>
								)}
							</FormContainer>
						</div>
					</div>
				</Container>
			</>
		);
	}
}

export default FormulaireProspect;
