import { useEffect, useState } from 'react';
import Radio, { RadioItem } from '../../radio/Radio';
import Input, { InputType } from '../../input/Input';
import { FormatCheckService, ReferentialsPublicService } from '../../../services';
import { AccountStepProps } from '../../../models/ComponentStepProps';
import AttachmentUpload from '../../attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../../services/AttachmentService';

function PersonnalInformationStep({ model, handleChange }: AccountStepProps) {
	const [genderItems, setGenderItems] = useState<RadioItem[]>([]);
	const [birthLastnameErrorMessage, setBirthLastnameErrorMessage] = useState('');
	const [birthFirstnameErrorMessage, setBirthFirstnameErrorMessage] = useState('');
	const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

	useEffect(() => {
		ReferentialsPublicService.getGenders().then((genders) => {
			let result = genders.map((x) => {
				return { DisplayName: x.name, Value: x.id, Checked: x.id == model.genderId };
			});
			setGenderItems(result);
		});
	}, []);

	useEffect(() => {
		setBirthLastnameErrorMessage(
			model.birthLastname !== '' && !FormatCheckService.checkLastName(model.birthLastname)
				? `Le champ Nom de naissance n’est pas valide.`
				: ''
		);
	}, [model.birthLastname]);

	useEffect(() => {
		setBirthFirstnameErrorMessage(
			model.birthFirstname !== '' && !FormatCheckService.checkFirstName(model.birthFirstname)
				? `Le champ Prénom n’est pas valide.`
				: ''
		);
	}, [model.birthFirstname]);

	useEffect(() => {
		setPhoneNumberErrorMessage(
			model.phoneNumber !== '' && !FormatCheckService.checkPhoneNumber(model.phoneNumber)
				? `Le format de numéro de téléphone saisi n’est pas valide. Format attendu : (+33)xxxxxxxxx`
				: ''
		);
	}, [model.phoneNumber]);

	const handleGenderIdChange = (evt: any) => {
		genderItems.filter((x) => x.Value !== +evt.currentTarget.value).map((x) => (x.Checked = false));
		genderItems.filter((x) => x.Value === +evt.currentTarget.value).map((x) => (x.Checked = true));
		handleChange(evt);
	};

	return (
		<div>
			<h5>Se créer un compte en renseignant les informations complémentaires ci-dessous</h5>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Radio
				title='Sexe'
				name='genderId'
				items={genderItems || []}
				isHorizontal={false}
				disabled={false}
				isSmall={false}
				onChange={(e: any) => handleGenderIdChange(e)}
			/>
			<Input
				title='Nom de naissance'
				name='birthLastname'
				type={InputType.Text}
				value={model.birthLastname || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={birthLastnameErrorMessage}
			/>
			<Input
				title='Prénom de naissance'
				name='birthFirstname'
				type={InputType.Text}
				value={model.birthFirstname || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={birthFirstnameErrorMessage}
			/>
			<Input
				title='Numéro de téléphone'
				description='Format attendu : (+33)xxxxxxxxx'
				name='phoneNumber'
				type={InputType.Text}
				value={model.phoneNumber || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={phoneNumberErrorMessage}
			/>
			<hr/>
			{ (['1', '2'].indexOf(localStorage.getItem('cv') || '0') !== -1 || 
			   ['1', '2'].indexOf(localStorage.getItem('lm') || '0') !== -1) && 
			<>
			<h5>Renseignez votre Curiculum Vitae (CV) et lettre de motivation</h5>
			{['1', '2'].indexOf(localStorage.getItem('cv') || '0') !== -1 && 
			<AttachmentUpload
				title='Pièce jointe'
				hintText='Curiculum Vitae (CV)'
				guid='PUBLIC'
				publicFunction={(file: File) => {
					model.cv = file;
					model.cvValidationState = AttachmentState.ADDED;
					handleChange(file);
				}}
				onDelete={() => {
					model.cv = undefined;
					model.cvValidationState = undefined;
					handleChange(model);
				}}
				isUploadInProgress={false}
				isDeleteInProgress={false}
				validationState={model.cvValidationState}
				publicFile={model.cv}></AttachmentUpload>
			}
			{['1', '2'].indexOf(localStorage.getItem('lm') || '0') !== -1 &&
			<AttachmentUpload
				hintText='Lettre de motivation'
				guid='PUBLIC'
				publicFunction={(file: File) => {
					model.lm = file;
					model.lmValidationState = AttachmentState.ADDED;
					handleChange(file);
				}}
				onDelete={() => {
					model.lm = undefined;
					model.lmValidationState = undefined;
					handleChange(model);
				}}
				isUploadInProgress={false}
				isDeleteInProgress={false}
				validationState={model.lmValidationState}
				publicFile={model.lm}></AttachmentUpload>
			}
			</>
			}
		</div>
	);
}

export default PersonnalInformationStep;
