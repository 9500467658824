import { v4 as uuid } from 'uuid';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Input, { InputType } from '../../components/input/Input';
import Checkbox from '../../components/checkbox/Checkbox';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Modal, { ModalSize } from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import EnumGroupingType from '../../enums/EnumGroupingType';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import { NephosESLScopPoolLanguage } from '../../sparta.api';
import { createRef } from 'react';
interface ICompetencesLanguesState extends IProfileState {
	currentLangue?: NephosESLScopPoolLanguage;
	currentLangueIndex: number;
	isLanguageLoading?: boolean;
	successMessage?: string;
	errorMessage?: string;
	otherlang: boolean;
}

class CompetencesLangues extends BaseComponent<ICompetencesLanguesState> {
	state: ICompetencesLanguesState = {
		isLanguageLoading: false,
		otherlang: false,
		isEdit: false,
		currentLangueIndex: -1
	};
	languageModalId: string = uuid();
	hasCheckedRadio: boolean = false;
	groupingType: number = EnumGroupingType.COMPETENCES_LANGUES;
	inputRef: any = createRef();
	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Langues'
							description='Veuillez remplir les informations demandées ci-dessous'
							size='h1'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isSuccess && this.state.errorMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.errorMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<h5>Langues</h5>
							<p>
								Pour ajouter une langue à votre dossier de candidature, veuillez cliquer sur le bouton “Ajouter
								une langue”
							</p>
							<Button
								text='Ajouter une langue'
								disabled={this.state.groupingIsValidate ?? false}
								size={ButtonSize.Medium}
								type={ButtonType.Primary}
								onClick={() => {
									this.setState(
										{
											currentLangueIndex: -1,
											otherlang: false,
											isEdit: false,
											currentLangue: undefined,
											isLanguageLoading: true
										},
										() => {
											this.setState({
												isLanguageLoading: false
											});
										}
									);
								}}
								modalId={this.languageModalId}></Button>
							<TileContainer>
								{this.state.profile?.professional?.languages?.map((x, i) => {
									return (
										<Tile
											key={i + ' ' + x.value?.code + ' ' + x.value?.value}
											title={x.value?.value || ''}
											onLoad={() =>
												ReferentialsService.getReferential(
													ReferentialType.LANGUAGE,
													+(x.value?.code || 0),
													x.value?.code == '0' ? x.value?.value : ''
												)
											}
											description={this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'}
											modalId={this.languageModalId}
											disabled={this.state.groupingIsValidate ?? false}
											onDelete={async () => {
												let isGroupingValidated = await this.checkIfCanDelete();
												if (!isGroupingValidated) {
													var languages = [...(this.state.profile?.professional?.languages || [])];
													languages.splice(i, 1);
													this.setState(
														{
															successMessage: 'La langue a été supprimée avec succès',
															profile: {
																...this.state.profile,
																professional: {
																	...this.state.profile?.professional,
																	languages: languages
																}
															}
														},
														() => {
															this.updateProfile();
														}
													);
												}
											}}
											onEdit={() => {
												this.setState(
													{
														currentLangueIndex: i,
														isEdit: true,
														currentLangue: this.state.profile?.professional?.languages?.at(i),
														isLanguageLoading: true
													},
													() => {
														this.setState({ isLanguageLoading: false });
													}
												);
												this.setState({ isLanguageLoading: false });
												this.setState({
													otherlang:
														this.state.profile?.professional?.languages?.at(i)?.value?.code == '0'
															? true || false
															: false
												});
											}}></Tile>
									);
								})}
							</TileContainer>
							<Modal
								inputRef={this.inputRef}
								title={this.state.isEdit ? 'Modifier la langue' : 'Ajouter une langue'}
								id={this.languageModalId}
								size={ModalSize.Large}
								custom={true}
								buttons={[
									{
										DisplayName: this.state.isEdit ? 'Modifier la langue' : 'Ajouter une langue',
										OnClick: () => {
											if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
												var otherlang = this.state.otherlang;
												var languageCode = this.state.currentLangue?.value?.code || '0';
												var levelCode = this.state.currentLangue?.level?.code || '0';
												var otherLangName = this.state.currentLangue?.value?.value || '';

												if (
													(languageCode != '0' && levelCode != '0' && !otherlang) ||
													(otherLangName != '' && levelCode != '0' && otherlang)
												) {
													var languages = [...(this.state.profile?.professional?.languages || [])];
													if (this.state.currentLangue) {
														if (this.state.currentLangueIndex === -1) {
															languages.push(this.state.currentLangue);
														} else {
															languages[this.state.currentLangueIndex] = this.state.currentLangue;
														}
													}
													this.setState(
														{
															successMessage: this.state.isEdit
																? 'La langue a été modifiée avec succès'
																: 'La langue a été ajoutée avec succès',
															errorMessage: undefined,
															profile: {
																...this.state.profile,
																professional: {
																	...this.state.profile?.professional,
																	languages: languages
																}
															}
														},
														() => {
															this.updateProfile();
															setTimeout(() => {
																this.inputRef.current.disabled = false;
															}, 2000);
														}
													);
												} else {
													this.setState(
														{
															successMessage: undefined,
															errorMessage: 'Tous les champs requis ne sont pas renseignés'
														},
														() => {
															this.displaySuccess();
														}
													);
												}
											}
										}
									}
								]}>
								<>
									{!this.state.isLanguageLoading && (
										<>
											<ReferentialSelect
												label='Langue pratiquée'
												type={ReferentialType.LANGUAGE}
												value={this.state.currentLangue?.value?.code}
												disabled={this.state.otherlang}
												onChange={(e) => {
													this.setState({
														currentLangue: {
															...this.state.currentLangue,
															value: {
																code: e?.id
															}
														}
													});
												}}></ReferentialSelect>
											<Checkbox
												items={[
													{
														Checked: this.state.otherlang ?? false,
														DisplayName: 'Autre langue',
														Value: this.state.otherlang ?? false
													}
												]}
												onChange={(e) => {
													this.setState({
														otherlang: e.currentTarget.checked
													});
												}}></Checkbox>
											{this.state.otherlang && (
												<Input
													title='Autre langue'
													type={InputType.Text}
													value={this.state.currentLangue?.value?.value}
													onChange={(e) =>
														this.setState({
															currentLangue: {
																...this.state.currentLangue,
																value: {
																	code: '0',
																	value: e
																}
															}
														})
													}></Input>
											)}
											<ReferentialSelect
												label='Niveau linguistique'
												type={ReferentialType.LANGUAGE_LEVEL}
												value={this.state.currentLangue?.level?.code}
												onChange={(e) => {
													this.setState({
														currentLangue: {
															...this.state.currentLangue,
															level: {
																code: e?.id,
																value: e?.name
															}
														}
													});
												}}></ReferentialSelect>
										</>
									)}
								</>
							</Modal>
						</FormContainer>
					</div>
				</div>
			</Container>
		);
	}
}
export default CompetencesLangues;
