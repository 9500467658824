import React from 'react';
import './0-JobCategorie-mobile.scss';
import Button, { ButtonSize, ButtonType } from '../button/Button';

class JobCard extends React.Component<
	{
		title: string;
		category: number;
		index: number;
		count: number;
		maxItem: number;
		isLock: boolean;
		onDelete: (title: string, category: number) => void;
		onUp: (currentIndex: number, category: number) => void;
		onDown: (currentIndex: number, category: number) => void;
	},
	{
		toDelete: string;
	}
> {
	constructor(props: any) {
		super(props);
		this.state = {
			toDelete: ''
		};
	}

	render() {
		var position = this.props.count;
		var countItem = this.props.maxItem;
		return (
			<>
				<div className='fr-grid-row for-card'>
					<div
						className='fr-col-2 fr-col-sm-1'
						style={{ textAlign: 'center', paddingTop: '45px' }}>
						<button
							className='fr-btn'
							style={{ borderRadius: '25px' }}>
							{position.toString()}
						</button>
					</div>
					<div className='fr-col-10 fr-col-sm-11'>
						<div className='fr-tile fr-enlarge-link fr-tile--horizontal for-job'>
							<div className='fr-tile__body'>
								<h4 className='fr-tile__title'>{this.props.title}</h4>
								<Button
									text='Supprimer'
									size={ButtonSize.Medium}
									type={ButtonType.Secondary}
									modalId={'delete-' + this.props.category}
									onClick={() => this.props.onDelete(this.props.title, this.props.category)}></Button>
							</div>
							<div className='fr-tile__body for-card for-job'>
								{(position == 1 || this.props.isLock) && (
									<>
										<button
											className='fr-btn fr-icon-arrow-up-line'
											title='Haut'
											style={{ marginLeft: '15px' }}
											disabled>
											Haut
										</button>
									</>
								)}
								{position != 1 && this.props.isLock == false && (
									<>
										<button
											className='fr-btn fr-icon-arrow-up-line'
											title='Haut'
											onClick={() => this.props.onUp(this.props.index, this.props.category)}
											style={{ marginLeft: '15px' }}>
											Haut
										</button>
									</>
								)}
								{(position == countItem || this.props.isLock) && (
									<>
										<button
											className='fr-btn fr-icon-arrow-down-line'
											title='Bas'
											style={{ marginLeft: '15px', marginTop: '5px' }}
											disabled>
											Bas
										</button>
									</>
								)}
								{position != countItem && this.props.isLock == false && (
									<>
										<button
											className='fr-btn fr-icon-arrow-down-line'
											title='Bas'
											onClick={() => this.props.onDown(this.props.index, this.props.category)}
											style={{ marginLeft: '15px', marginTop: '5px' }}>
											Bas
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default JobCard;
