import StepContainer from '../../components/step/StepContainer2';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import PageTitle from '../../components/page-title/PageTitle';
import CirfaStep from '../../components/step/common/CirfaStep';
import { useEffect, useState } from 'react';
import ReferentialsService from '../../services/ReferentialsService';
import { DateService, FormatCheckService, ProfileService, QueryStringService, StepService } from '../../services';
import { Appointment, Step } from '../../models';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import Link, { LinkSize } from '../../components/link/Link';
import PersonnalInformationRDVStep from '../../components/step/priseRDV/PersonnalInformationRDVStep';
import { TypeRDVStep } from '../../components/step';
import CreneauRDVStep from '../../components/step/priseRDV/CreneauRDVStep';
import AppointmentAvailabilityPublicService from '../../services/public/AppointmentAvailabilityPublicService';
import FrenchNationalityStep from '../../components/step/priseRDV/FrenchNationalityStep';
import ConfirmationRDV from '../../components/step/priseRDV/ConfirmationRDV';
import AppointmentService, { AppointmentCreateModel, SendAppointmentMailModel } from '../../services/AppointmentService';
import AppointmentPublicService from '../../services/public/AppointmentPublicService';
import AgeStep from '../../components/step/priseRDV/AgeStep';
import profileService from '../../services/ProfileService';
import '../PriseDeRDV/0-PriseDeRDV-mobile.scss';

interface Props {
	location: Location;
}

function PriseDeRDVFunc({ location }: Props) {
	const [forceNextStep, setForceNextStep] = useState(0);
	const [forcePreviousStep, setForcePreviousStep] = useState(-1);
	const [appointmentCreated, setAppointmentCreated] = useState(false);
	const [associatedRecruiter, setAssociatedRecruiter] = useState<any>();
	const [loadAssociatedRecruiter, setLoadAssociatedRecruiter] = useState(false);
	const [appointmentFullDate, setAppointmentFullDate] = useState('');
	const [appointmentPlace, setAppointmentPlace] = useState('');
	const [army, setArmy] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const [newAppointment, setNewAppointment] = useState<Appointment>({
		firstname: '',
		lastname: '',
		email: '',
		jobCategoryId: undefined,
		cirfaCategoryId: 0,
		zipCode: undefined,
		cirfaId: undefined,
		appointmentTypes: [],
		appointmentSlotSelected: undefined,
		army: undefined,
		birthDate: undefined,
		hasFrenchNationality: undefined,
		phoneNumber: '',
		complementaryInformation: '',
		acceptance: false,
		isValidCapcha: false,
		profile: undefined,
		appointmentError: '',
		hasApplicantAccount: false,
		configurationId: 0,
		pageNumber: 0
	});

	useEffect(() => {
		document.title = 'Espace Candidat - Prendre rendez-vous';
		QueryStringService.initialiseLocalStorageForAppointment(location);
		setNewAppointment((oldValue: any) => ({ ...oldValue, cirfaCategoryId: +(localStorage.getItem('jobCategoryId') || 0) }));
		ReferentialsService.getReferentialArmy()
			.then((army) => {
				setArmy(army);
				setNewAppointment((oldValue: any) => ({ ...oldValue, army: army }));
				if (localStorage.getItem('auth')) {
					profileService.getAssociatedRecruiter(army.id).then((recruiter) => {
						setAssociatedRecruiter(recruiter);
						setLoadAssociatedRecruiter(true);
					});
				} else {
					setLoadAssociatedRecruiter(true);
				}
			})
			.catch((error) => {});
		if (localStorage.getItem('auth')) {
			ProfileService.get().then((response: any) => {
				setNewAppointment((oldValue: any) => ({ ...oldValue, profile: response }));
				setNewAppointment((oldValue: any) => ({ ...oldValue, firstname: response.personal?.identity?.firstName }));
				setNewAppointment((oldValue: any) => ({ ...oldValue, lastname: response.personal?.identity?.lastName }));
				setNewAppointment((oldValue: any) => ({ ...oldValue, birthDate: response.personal?.identity?.dateOfBirth }));
				setNewAppointment((oldValue: any) => ({ ...oldValue, email: response.personal?.contactDetails?.email }));
				setNewAppointment((oldValue: any) => ({
					...oldValue,
					hasFrenchNationality: response.personal?.identity?.nationalities?.currentNationalityId === 27
				}));
				setNewAppointment((oldValue: any) => ({
					...oldValue,
					phoneNumber: response.personal?.contactDetails?.phoneNumbers?.at(0)?.number
				}));
			});
		}
	}, []);

	const handleAppointmentChange = (evt: any): void => {
		if (evt.target) {
			const key = evt.target.name as string;

			if (evt.target.type == 'radio') {
				if (key === 'hasFrenchNationality') {
					setNewAppointment((oldValue) => ({ ...oldValue, [key]: evt.target?.value == 'true' }));
				}
			} else {
				const valueInput = evt.target.type == 'checkbox' ? evt.target.checked : evt.target.value;
				setNewAppointment((oldValue) => ({ ...oldValue, [key]: valueInput }));
				if (key == 'cirfaId') {
					setForceNextStep((value) => value + 1);
				}
			}
		} else {
			if (evt.name == 'cirfaCategoryId') {
				setNewAppointment((oldValue) => ({ ...oldValue, [evt.name]: +evt?.value }));
			} else {
				setNewAppointment((oldValue) => ({ ...oldValue, [evt.name]: evt.value }));
				if (evt.name === 'appointmentSlotSelected') {
					setForceNextStep((value) => value + 1);
				}
			}

			if (evt.name == 'hasApplicantAccount') {
				setNewAppointment((oldValue) => ({ ...oldValue, [evt.name]: evt.value }));
			}

			if (evt.name === 'configurationIdAndAppointmentTypeSelected') {
				setNewAppointment((oldValue) => ({
					...oldValue,
					configurationId: evt.value.configurationId,
					appointmentModalityTypeSelected: evt.value.appointmentModalityTypeSelected
				}));
				setNewAppointment((oldValue) => ({
					...oldValue,
					appointmentTypeSelected: evt.value.appointmentTypeSelected,
					appointmentModalityTypeSelected: evt.value.appointmentModalityTypeSelected
				}));
				setForceNextStep((value) => value + 1);
			}
		}
	};

	const validStep1 = () => {
		return (
			newAppointment.firstname != undefined &&
			newAppointment.firstname !== '' &&
			FormatCheckService.checkFirstName(newAppointment.firstname) &&
			newAppointment.lastname !== undefined &&
			newAppointment.lastname !== '' &&
			FormatCheckService.checkLastName(newAppointment.lastname) &&
			newAppointment.email !== undefined &&
			newAppointment.email !== '' &&
			FormatCheckService.checkEmail(newAppointment.email) &&
			!newAppointment.hasApplicantAccount
		);
	};
	const validStep2 = () => {
		const result = newAppointment.cirfaId !== undefined && newAppointment.cirfaId !== 0 ? true : false;
		return result;
	};
	const validStep3 = () => {
		const result = newAppointment.configurationId !== undefined && newAppointment.configurationId !== 0 ? true : false;
		return result;
	};
	const validStep4 = () => {
		const result =
			newAppointment.appointmentSlotSelected !== undefined && newAppointment.appointmentSlotSelected !== null
				? true
				: false;
		return result;
	};
	const validStep5 = () => {
		let valid = true;

		if (
			newAppointment.birthDate === undefined ||
			(newAppointment.birthDate !== undefined && DateService.getAgeFromString(newAppointment.birthDate) < army?.minAge) ||
			(newAppointment.birthDate !== undefined &&
				DateService.getAgeFromString(newAppointment.birthDate) > +(localStorage.getItem('maxAge') || army?.maxAge))
		) {
			valid = false;
		}

		return valid;
	};
	const validStep6 = () => {
		if (newAppointment.isValidCapcha) {
			setNewAppointment((oldValue) => ({ ...oldValue, isValidCapcha: false }));
		}
		return newAppointment.hasFrenchNationality === true;
	};
	const validStep7 = () => {
		return (
			newAppointment.phoneNumber !== undefined &&
			newAppointment.phoneNumber !== '' &&
			FormatCheckService.checkPhoneNumber(newAppointment.phoneNumber) &&
			newAppointment.acceptance &&
			(newAppointment.isValidCapcha || process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY === undefined)
		);
	};

	const step1: Step = StepService.BuildStep(
		'Renseigner votre nom, prénom et adresse électronique',
		<PersonnalInformationRDVStep
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep1
	);
	const step2: Step = StepService.BuildStep(
		'Trouvez votre lieu de rendez-vous',
		<CirfaStep
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep2
	);
	const step3: Step = StepService.BuildStep(
		'Choisir un type de rendez-vous',
		<TypeRDVStep
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep3
	);
	const step4: Step = StepService.BuildStep(
		'Choisir un créneau horaire',
		<CreneauRDVStep
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep4,
		undefined,
		true
	);
	const step5: Step = StepService.BuildStep(
		'Indiquer votre âge',
		<AgeStep
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep5
	); // army optimisé
	const step6: Step = StepService.BuildStep(
		'Indiquer votre nationalité',
		<FrenchNationalityStep
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep6
	); // army
	const step7: Step = StepService.BuildStep(
		'Confirmer votre rendez-vous',
		<ConfirmationRDV
			model={newAppointment}
			handleChange={handleAppointmentChange}
		/>,
		validStep7,
		true
	);

	const steps = [step1, step2, step3, step4, step5, step6, step7];
	const stepsExistingProfile = [...steps];
	stepsExistingProfile.splice(0, 1);
	stepsExistingProfile.splice(3, 2); // etapes : 2,3,4,7

	const handleSubmitForm = () => {
		var appointmentModel: AppointmentCreateModel = {
			ApplicantID: newAppointment.profile?.id || 0,
			ApplicantComment: newAppointment.complementaryInformation,
			JobCategoryId: newAppointment.cirfaCategoryId || 0,
			TenantID: 3,
			ShowCaseWebSiteVacancyLabel: localStorage.getItem('jobTitle') || '',
			AppointmentId: newAppointment.appointmentSlotSelected?.appointmentId || 0,
			Applicant: {
				ArmyId: +(ReferentialsService.getArmy() || ''),
				BirthDate: newAppointment.birthDate || '',
				BirthFirstname: newAppointment.firstname,
				BirthLastname: newAppointment.lastname,
				CirfaId: newAppointment.cirfaId || 0,
				Email: newAppointment.email,
				FrenchNationality: newAppointment.hasFrenchNationality || false,
				JobCategoryId: newAppointment.cirfaCategoryId || 0,
				JobTitle: localStorage.getItem('jobTitle') || '',
				OriginID: +(localStorage.getItem('originId') || 0),
				PhoneNumber: newAppointment.phoneNumber,
				RGPDAcceptance: newAppointment.acceptance
			}
		};
		createAppointment(appointmentModel).then(() => {});
	};

	const reInitilized = () => {
		setForcePreviousStep(-1);
	};

	const afterAppointmentCreation = (response: any) => {
		setAppointmentCreated(true);
		setNewAppointment((oldValue) => ({ ...oldValue, appointmentError: '' }));
		setIsLoading(false);
		setAppointmentFullDate(response.data.fullDate);
		setAppointmentPlace(response.data.cirfaName);
	};

	const createAppointment = async (model: AppointmentCreateModel) => {
		setIsLoading(true);
		await checkAvailabilityAppointment(model)
			.then((resp) => {
				if (resp.data) {
					if (localStorage.getItem('auth')) {
						// On est connecté, on utilise le controller privé (besoin du token)
						AppointmentService.createAppointment(model).then((response) => {
							afterAppointmentCreation(response);
						});
					} else {
						// On n'est pas connecté, on utilise le controller public
						AppointmentPublicService.createAppointment(model).then((response) => {
							afterAppointmentCreation(response);
						});
					}
				} else {
					setAppointmentCreated(false);
					setNewAppointment((oldValue) => ({
						...oldValue,
						appointmentError: (resp.errors?.[0]?.message || '') + ' Veuillez sélectionner un nouveau créneau.'
					}));
					setIsLoading(false);
					setAppointmentFullDate('');
					setAppointmentPlace('');
					if (localStorage.getItem('auth')) {
						setForcePreviousStep(1);
					} else {
						setForcePreviousStep(2);
					}
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const checkAvailabilityAppointment = async (model: AppointmentCreateModel) => {
		return await AppointmentAvailabilityPublicService.check({
			TenantID: 3,
			AppointmentId: model.AppointmentId || 0,
			ApplicantEmail: model.Applicant.Email
		});
	};

	const sendAppointmentMail = () => {
		var sendAppointmentMailModel: SendAppointmentMailModel = {
			Email: newAppointment.email,
			TenantID: 3,
			AppointmentId: newAppointment.appointmentSlotSelected?.appointmentId || 0
		};
		AppointmentPublicService.sendAppointmentMail(sendAppointmentMailModel);
	};

	return (
		<Container isLoading={isLoading}>
			{!appointmentCreated && associatedRecruiter && loadAssociatedRecruiter && (
				<>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div
							className='fr-col-11 fr-col-lg-8'
							style={{ paddingTop: '2rem ' }}>
							<PageTitle
								title='Prendre rendez-vous'
								description="Moins de 2 minutes seront nécessaires. Pour cela, merci de renseigner ces quelques informations. Ne concerne pas la réserve militaire. Pour se renseigner ou candidater pour la réserve : <a href='https://www.reservistes.defense.gouv.fr/' title='reservistes.defense.gouv.fr' target='_blank' rel='noopener'>reservistes.defense.gouv.fr</a>"
								size='h1'
								></PageTitle>
						</div>
					</div>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div className='fr-col-11 fr-col-lg-8'>
							<FormContainer>
								<span className='fr-hint-text account-creation-hint'>
									Cette adresse électronique est déjà associée à un compte existant. Veuillez vous connectez{' '}
									<a
										href={'/login?army_id=' + army.id}
										rel='noopener'>
										ici
									</a>{' '}
									pour prendre un rendez-vous.
								</span>
							</FormContainer>
						</div>
					</div>
				</>
			)}
			{!appointmentCreated && !associatedRecruiter && loadAssociatedRecruiter && (
				<>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div
							className='fr-col-11 fr-col-lg-8'
							style={{ paddingTop: '2rem ' }}>
							<PageTitle
								title='Prendre rendez-vous'
								description="Moins de 2 minutes seront nécessaires. Pour cela, merci de renseigner ces quelques informations. Ne concerne pas la réserve militaire. Pour se renseigner ou candidater pour la réserve : <a href='https://www.reservistes.defense.gouv.fr/' title='reservistes.defense.gouv.fr' target='_blank' rel='noopener'>reservistes.defense.gouv.fr</a>"
								size='h1'
								></PageTitle>
						</div>
					</div>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div className='fr-col-11 fr-col-lg-8'>
							<FormContainer>
								<StepContainer
									steps={localStorage.getItem('auth') ? stepsExistingProfile : steps}
									model={newAppointment}
									forceNextStep={forceNextStep}
									forcePreviousStep={forcePreviousStep}
									reInitilized={reInitilized}
									submitForm={() => handleSubmitForm()}
								/>
							</FormContainer>
						</div>
					</div>
				</>
			)}
			{appointmentCreated && !associatedRecruiter && loadAssociatedRecruiter && (
				<>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div
							className='fr-col-11 fr-col-lg-8'
							style={{ paddingTop: '2rem ' }}>
							<PageTitle
								title='Confirmation de votre demande de rendez-vous'
								description=''></PageTitle>
						</div>
					</div>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div className='fr-col-11 fr-col-lg-8'>
							<FormContainer>
								<Alert
									description={`Votre demande de prise de rendez-vous a bien été prise en compte pour le <b>${appointmentFullDate} au ${appointmentPlace}</b>`}
									size={AlertSize.Small}
									type={AlertType.Success}
									isShown={true}
									onHide={() => setIsSuccess(false)}
								/>
								<p className='fr-hint-text account-creation-hint'>
									Détail — Si vous n'avez pas reçu de courriel (n'hésitez pas à vérifier dans les indésirables),
									vous pouvez saisir à nouveau votre adresse électronique.
								</p>
								<Link
									title={
										localStorage.getItem('auth')
											? "Retour sur l'espace candidat"
											: `Retour sur le site ${army?.website}`
									}
									size={LinkSize.Medium}
									link={localStorage.getItem('auth') ? '/' : 'https://' + army?.website}></Link>
								<hr className='fr-hr' />
								<p>Vous n'avez pas reçu de mail ?</p>
								<div className='send-back-mail'>
									<Link
										title='Me renvoyer le mail'
										size={LinkSize.Medium}
										onClick={() => sendAppointmentMail()}></Link>
								</div>
							</FormContainer>
						</div>
					</div>
				</>
			)}
		</Container>
	);
}

export default PriseDeRDVFunc;
